import React from 'react';
// import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import './fonts/Barlow/Barlow-Black.ttf';
import './fonts/Barlow/Barlow-BlackItalic.ttf';
import './fonts/Barlow/Barlow-Bold.ttf';
import './fonts/Barlow/Barlow-BoldItalic.ttf';
import './fonts/Barlow/Barlow-ExtraBold.ttf';
import './fonts/Barlow/Barlow-ExtraBoldItalic.ttf';
import './fonts/Barlow/Barlow-ExtraLight.ttf';
import './fonts/Barlow/Barlow-ExtraLightItalic.ttf';
import './fonts/Barlow/Barlow-Italic.ttf';
import './fonts/Barlow/Barlow-Light.ttf';
import './fonts/Barlow/Barlow-LightItalic.ttf';
import './fonts/Barlow/Barlow-Medium.ttf';
import './fonts/Barlow/Barlow-MediumItalic.ttf';
import './fonts/Barlow/Barlow-Regular.ttf';
import './fonts/Barlow/Barlow-SemiBold.ttf';
import './fonts/Barlow/Barlow-SemiBoldItalic.ttf';
import './fonts/Barlow/Barlow-Thin.ttf';
import './fonts/Barlow/Barlow-ThinItalic.ttf';
import './fonts/VAG Rounded/VAG_Rounded_Light.ttf';
import './fonts/VAG Rounded/VAG_Rundschrift.ttf';

// ReactDOM.render(
//     <React.StrictMode>
//         <App />
//     </React.StrictMode>,
//     document.getElementById('root')
// );

// ReactDOM.render(
//     <App />,
//     document.getElementById('root')
// );

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <App />
);
