import React, {useEffect, useRef, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {useLocation, useParams} from "react-router";
import useFetch from "./UseFetch";
import InfiniteScroll from "react-infinite-scroll-component";
import usePrevious from "./usePrevious";
import {Autocomplete, Grid, IconButton} from "@mui/material";
import Button from "@mui/material/Button";
import {ReactComponent as TrashIcon} from "../images/svg/mulleimer.svg";
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import {useHistory} from "react-router-dom";
import {ReactSession} from "react-client-session";
import {toast} from "react-toastify";
import {CssTextField2} from "./CssTextField2";
import ImageUploading from "react-images-uploading";
import {CustomButton} from "./CustomButton";
import ClearIcon from "@mui/icons-material/Clear";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import moment from 'moment';
export default function RechnungenVerwalten(props) {

    const {locationChange} = props;
    const location = useLocation();
    const params = useParams();
    const api = useFetch();
    const history = useHistory();


    const [pageNumber, setPageNumber] = useState(1);
    const [user, setUser] = useState(false);
    const [hasMoreData, setHasMoreData] = useState(false);
    const [invoice, setInvoice] = useState(false);
    const [invoiceId, setInvoiceId] = useState(false);
    const [invoiceEvent, setInvoiceEvent] = useState(false);
    const [invoices, setInvoices] = useState([]);
    const [invoiceName, setInvoiceName] = useState('');
    const [isFocused, setIsFocused] = useState(null);
    const [autocomplete, setAutocomplete] = useState([]);
    const [selectedAutocomplete, setSelectedAutocomplete] = useState(undefined);
    const [selectedAutocompleteInput, setSelectedAutocompleteInput] = useState('');
    const autocompleteRef = useRef([]);

    useEffect(() => {
        document.title = 'swiitcher';
        locationChange(location, params.token);
        getPurchaseOverviews();
    }, [])

    const getUser = async (value) => {
        let url = ENV.REACT_APP_API_URL + '/user/' + value.id;
        let {response, data} = await api(url);
        console.log(data);
        if (data && data.data) {
            setUser(data.data);
        }
    }

    useEffect(() => {
        getPurchaseOverviews();
    }, [user])

    const notify = (message, error) => {
        console.log('NOTIFY')
        if (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        } else {
            toast.success(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        }
    }

    const getPDF = async (url) => {

        let token = ReactSession.get('token');
        let headers = new Headers();
        let config;

        if (!url.includes('/user/login')) {
            headers.append('Authorization', `Bearer ${token}`);
        }
        config = {
            method: 'GET',
            headers: headers
        }

        let response = await fetch(url, config);
        if (response.body) {
            //Create a Blob from the PDF Stream
            const blob = await response.blob();
            //Build a URL from the file
            const fileURL = URL.createObjectURL(blob);
            //Open the URL on new Window
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;
        }
    }

    const newData = () => {
        console.log('newData')
        if (hasMoreData === true) {
            let pageN = pageNumber;
            let temp = ++pageN;
            console.log('setpageNumber')
            setPageNumber(temp);
        }
    }

    useEffect(() => {
        if (pageNumber) {
            console.log(pageNumber);
            getPurchaseOverviews();
        }
    }, [pageNumber])

    useEffect(() => {
        if (!selectedAutocomplete) {
            setUser(false);
        }
    }, [selectedAutocomplete])

    const uploadFile = async () => {
        console.log('uploadFile')
        console.log(user);
        const formData = new FormData();
        formData.append(
            'data',
            JSON.stringify({
                data: {
                    attributes: {
                        invoice_id: invoiceId
                    },
                },
            }),
        );
        console.log(invoice);
        formData.append('file', invoice);

        let url = ENV.REACT_APP_API_URL + "/purchase-overviews";
        const {response, data} = await api(url, 'POST', formData, true);
        console.log('data');
        console.log(data);

        if (response && response.status != '404' && response.status != '500' && data) {
            notify('Rechnung wurde erfolgreich hochgeladen');
            getPurchaseOverviews();
        } else if (response.status == '404') {

        }
    }

    const getPurchaseOverviews = async () => {
        let temp = [];
        let hasMore = true;

        for (let i = 1; i < pageNumber + 1; i++) {
            let url = ENV.REACT_APP_API_URL + '/purchase-overviews?&include=invoice,invoiceAddress&page%5Bsize%5D=6&page%5Bnumber%5D=' + i;

            if (user) {
                console.log(user);
                url += '&filter[creator_id]=' + user.id;
            }

            let {response, data} = await api(url);
            console.log(data);
            if (data && data.data) {
                console.log('page: ' + i);
                console.log(data.data);
                data.data.map((item, index) => {
                    item.attributes.invoice = data.included ? data.included.filter(i => i.id == item.attributes.invoice_id)[0] ?? null : null;
                    item.attributes.invoiceAddress = data.included ? data.included.filter(i => i.id == item.attributes.invoiceAddress_id)[0] ?? null : null;
                    if (temp.filter(i => i.id === item.id).length === 0) {
                        temp.push(item);

                    } else {
                        console.log(temp);
                        console.log(temp.indexOf(temp.filter(i => i.id === item.id)[0]));

                        temp[temp.indexOf(temp.filter(i => i.id === item.id)[0])] = item;
                    }
                })

                if (data.meta.pagination.total === temp.length) {
                    hasMore = false;
                }

            }

        }

        console.log(temp);
        temp.sort((a, b) => (a.attributes.boughtAt > b.attributes.boughtAt ? -1 : 1));
        setInvoices(temp);
        setHasMoreData(hasMore);
    }

    function currencyFormat(num) {
        if (num) {
            return num.toFixed(2).toString().replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
        }
        return false;
    }

    return (
        <React.Fragment>
            <div
                id='top-container'
                style={{
                    width: '100% +1px',
                    minHeight: 'calc(100vh - 123px)',
                    height: 'auto',
                    marginTop: 64,
                    marginLeft: '-1px',
                    position: 'relative',
                    background: '#00374e',
                }}
            >
                <div style={{padding: 50}}>
                    <div>
                        <Autocomplete
                            options={autocomplete}
                            getOptionLabel={(option) => {
                                return option.companyName;
                            }}
                            ref={autocompleteRef}
                            name={''}
                            freeSolo
                            openOnFocus={false}
                            value={selectedAutocomplete}
                            onChange={(event, newValue) => {
                                setSelectedAutocomplete(newValue);
                                getUser(newValue)
                            }}
                            inputValue={selectedAutocompleteInput}
                            onInputChange={async (event, newInputValue) => {
                                // let temp = [...questions];
                                console.log(newInputValue);
                                let t = [];
                                if (newInputValue && newInputValue.length > 2) {
                                    let url = ENV.REACT_APP_API_URL + '/users?filter%5BcompanyName%5D%5Blike%5D=' + newInputValue;

                                    let {response, data} = await api(url);
                                    console.log(data);

                                    if (data && data.data) {
                                        data.data.map((item, index) => {

                                            t.push({
                                                id: item.id,
                                                companyName: item.attributes.companyName
                                            })
                                        })
                                    }
                                }
                                if (newInputValue == undefined || newInputValue == 'undefined') {
                                    newInputValue = '';
                                }
                                setAutocomplete(t)
                                setSelectedAutocompleteInput(newInputValue);
                            }}
                            open={(autocomplete.length > 0 && (!selectedAutocomplete || (selectedAutocomplete.companyName != selectedAutocompleteInput)))}
                            className={'text-input-label-white ' + (isFocused === ('answer') || (selectedAutocomplete && selectedAutocomplete.length > 0) ? '' : 'borderWhite')}
                            onFocus={() => {
                                setIsFocused(('answer'))
                            }}
                            onBlur={() => {
                                setIsFocused(null)
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            renderInput={(params) =>
                                <>
                                    <CssTextField2
                                        {...params}
                                        sx={{input: {color: 'white'}}}
                                        label={'User'}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        variant="outlined"
                                        style={{
                                            width: '80%',
                                            margin: '5px 0 0 0',
                                            padding: 0,
                                        }}
                                    />
                                </>
                            }
                        />
                    </div>
                    <div style={{margin: 50, color: 'white'}}>


                        <h3>Liste mit vorhandenen Rechnungen {user && <span>für Firma: {user.attributes.companyName}</span>}</h3>

                        {invoices && <InfiniteScroll
                            dataLength={invoices ? invoices.length : 0} //This is important field to render the next data
                            next={newData}
                            hasMore={hasMoreData}
                            loader={<h4>Loading...</h4>}
                            endMessage={
                                <p style={{textAlign: 'center'}}>
                                    <b>No more items to load</b>
                                </p>
                            }
                            style={{overflow: 'unset'}}
                        >
                            <Grid container style={{display: 'block'}}>
                                {invoices.map((item, index) => {
                                    return <div key={index} style={{
                                        display: 'flex',
                                        borderTop: '2px solid',
                                        borderLeft: '2px solid',
                                        borderRight: '2px solid',
                                        borderBottom: index == invoices.length - 1 ? '2px solid' : ''
                                        // background: (index % 2 === 0) ? 'transparent' : 'lightgray'
                                    }}>
                                        <Grid key={index} item xs={12} style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            textAlign: 'left',
                                            height: '100%',
                                            width: '100%',
                                            padding: '10px 0 10px 10px'
                                        }}>
                                            <>
                                                <Grid container>
                                                    <Grid item xs={3} style={{borderRight: '2px solid white'}}>
                                                        <Grid item xs={12} style={{paddingTop: 10}}>
                                                            <p style={{margin: 0}}>{item.attributes.invoiceAddress.attributes.companyName}</p>
                                                            <p style={{margin: 0}}>{item.attributes.invoiceAddress.attributes.street}&nbsp;{item.attributes.invoiceAddress.attributes.houseNumber}</p>
                                                            <p style={{margin: 0}}>{item.attributes.invoiceAddress.attributes.zipcode}&nbsp;{item.attributes.invoiceAddress.attributes.city}</p>
                                                            <p style={{margin: 0}}>{item.attributes.invoiceAddress.attributes.telephoneNumber}</p>
                                                            <p style={{margin: 0}}>{item.attributes.invoiceAddress.attributes.email}</p>
                                                            <p style={{margin: 0}}>{item.attributes.invoiceAddress.attributes.website}</p>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={4} style={{paddingLeft: 20, paddingTop: 10}}>
                                                        <Grid container>
                                                            <Grid item xs={6}>
                                                                <p style={{margin: 0}}>Gekauft am:</p>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <p style={{margin: 0}}>{moment(item.attributes.boughtAt).format('DD.MM.YYYY HH:mm:ss')}</p>
                                                            </Grid>

                                                            {item.attributes.singleJobAdvertisement && <><Grid item xs={6}>
                                                                <p style={{margin: 0}}>Typ:</p>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <p style={{margin: 0}}>Einzelanzeige</p>
                                                            </Grid></>}

                                                            {item.attributes.flat && <><Grid item xs={6}>
                                                                <p style={{margin: 0}}>Typ:</p>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <p style={{margin: 0}}>Flat</p>
                                                            </Grid></>}

                                                            {item.attributes.matches && <><Grid item xs={6}>
                                                                <p style={{margin: 0}}>Typ:</p>
                                                            </Grid>
                                                                <Grid item xs={6}>
                                                                    <p style={{margin: 0}}>Matches</p>
                                                                </Grid></>}

                                                            {(item.attributes.flat || item.attributes.singleJobAdvertisement) && <><Grid item xs={6}>
                                                                <p style={{margin: 0}}>Mitarbeiterzahl:</p>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <p style={{margin: 0}}>{item.attributes.mitarbeiterzahl}</p>
                                                            </Grid></>}
                                                            {(item.attributes.matches) && <><Grid item xs={6}>
                                                                <p style={{margin: 0}}>Matchanzahl:</p>
                                                            </Grid>
                                                                <Grid item xs={6}>
                                                                    <p style={{margin: 0}}>{item.attributes.matches}</p>
                                                                </Grid></>}

                                                            <Grid item xs={6}>
                                                                <p style={{margin: 0}}>Preis:</p>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <p style={{margin: 0}}>{currencyFormat(item.attributes.priceInEuro)}</p>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={5} style={{
                                                        display: item.attributes.invoice ? 'flex' : 'block',
                                                        paddingRight: item.attributes.invoice ? '10px' : 0,
                                                        justifyContent: 'right',
                                                        alignItems: 'center'
                                                    }}>
                                                        {!item.attributes.invoice ? <div style={{
                                                            position: 'relative',
                                                            margin: ('10px ' + ((invoice && invoiceId === item.id) ? '102px' : '15px') + ' 10px' + ' auto'),
                                                            maxWidth: (invoice && invoiceId === index) ? 141 : 163,
                                                            height: '100%'
                                                        }}>
                                                            <label className={'filePickerLabel'}
                                                                   id={"filePickerLabel" + index.toString()}
                                                                   htmlFor={"filePicker" + index.toString()}
                                                                   style={{position: 'absolute', top: 0, left: 0}}>
                                                                Rechnung {(invoice && invoiceId === item.id) ? 'ändern' : 'auswählen'}
                                                            </label>
                                                            {(invoice && invoiceId === item.id) &&
                                                                <CustomButton style={{
                                                                    position: 'absolute',
                                                                    right: -65,
                                                                    padding: '1.5px 0'
                                                                }} buttontype={'yellowWhite2'}
                                                                              onClick={() => uploadFile(item.id)}>
                                                                    <FileUploadIcon
                                                                        className={'not-yellow-white'}
                                                                        onClick={() => {
                                                                            setInvoice(false);
                                                                        }}
                                                                        style={{
                                                                            height: 25,
                                                                            width: 25,
                                                                            textAlign: 'center',
                                                                            cursor: 'pointer',
                                                                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                                        }}
                                                                    />
                                                                </CustomButton>}
                                                            {(invoice && invoiceId === item.id) && <ClearIcon
                                                                className={'not-yellow-white'}
                                                                onClick={() => {
                                                                    setInvoice(false);
                                                                }}
                                                                style={{
                                                                    position: 'absolute',
                                                                    right: -95,
                                                                    height: 30,
                                                                    width: 30,
                                                                    transition: 'all 0.2s ease-in-out',
                                                                    textAlign: 'center',
                                                                    cursor: 'pointer',
                                                                    borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                                }}
                                                            />}
                                                            {(invoice && invoiceId === item.id) && <p style={{
                                                                position: 'absolute',
                                                                margin: 0,
                                                                top: 6,
                                                                left: -95,
                                                            }}>1 File gewählt</p>}
                                                            <span style={{display: 'block', minHeight: 30}}></span>
                                                            <input id={"filePicker" + index.toString()}
                                                                   style={{display: 'none'}} name="file1" type="file"
                                                                   accept="application/pdf" onChange={(e) => {
                                                                console.log(item.id);
                                                                setInvoiceId(item.id);
                                                                setInvoice(e.target.files[0]);
                                                                setInvoiceName(e.target.files[0].name);

                                                            }}/>
                                                            {/*{(invoice && invoiceId === item.id) && false && <div style={{marginTop: 10}}>*/}
                                                            {/*    <CustomButton style={{padding: 0}} buttontype={'yellowWhite2'}*/}
                                                            {/*                  onClick={handleSubmission}>*/}
                                                            {/*        <FileUploadIcon*/}
                                                            {/*            className={'not-yellow-white'}*/}
                                                            {/*            onClick={() => {*/}
                                                            {/*                setInvoice(false);*/}
                                                            {/*            }}*/}
                                                            {/*            style={{*/}
                                                            {/*                height: 30,*/}
                                                            {/*                width: 30,*/}
                                                            {/*                textAlign: 'center',*/}
                                                            {/*                cursor: 'pointer',*/}
                                                            {/*                borderRadius: ENV.REACT_APP_BORDER_RADIUS,*/}
                                                            {/*            }}*/}
                                                            {/*        />*/}
                                                            {/*    </CustomButton>*/}
                                                            {/*</div>}*/}
                                                        </div> : <><p
                                                            style={{margin: '10px 10px 10px auto', textAlign: 'right', textDecoration: 'underline', cursor: 'pointer'}}><a style={{color: 'white'}} target={'_blank'} onClick={() => getPDF(ENV.REACT_APP_API_URL + '/file/' + item.attributes.invoice?.id + '/download/content')}>{item.attributes.invoice?.attributes.fileName}</a></p></>}

                                                    </Grid>


                                                </Grid>
                                            </>
                                        </Grid>
                                    </div>
                                })}
                            </Grid>
                        </InfiniteScroll>}

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

