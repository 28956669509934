import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useParams } from "react-router";
import { ReactSession } from "react-client-session";
import useFetch from "./UseFetch";
import { Grid } from "@mui/material";
import './home.css'

export default function Home(props) {

    const { locationChange } = props;
    const location = useLocation();
    const params = useParams();
    const api = useFetch();

    const [allCounts, setAllCounts] = useState([])
    const date = new Date()
    date.setDate(date.getDate() - 1);
    const yesterday = date.toLocaleDateString();


    // ? withProfil === anonymes Profil


    useEffect(() => {
        document.title = 'swiitcher';
        locationChange(location, params.token);

        setTimeout(async () => {
            if (ReactSession.get('user')) {
                await getCounts()
            }
            //     // ? need this method of executing getCounts() or otherwise reactSession is not ready to return true
            //     // ? old useEffect method did not stop to fetch data -> slower page etc.
        }, 500)
    }, [])

    // useEffect(async () => {
    //     await getCounts()
    // }, [allCounts.length === 0])

    const getCounts = async () => {
        console.log('updating data')
        let url = ENV.REACT_APP_API_URL + '/user/get-user-counts';
        let { response, data } = await api(url);

        setAllCounts([data.data])
    }


    return (
        <React.Fragment>
            <div
                style={{
                    width: '100% +1px',
                    minHeight: 'calc(100vh - 123px)',
                    height: 'auto',
                    marginTop: 64,
                    paddingTop: 50,
                    marginLeft: '-1px',
                    position: 'relative',
                }}
            >
                {ReactSession.get('user') && <>
                    <Grid container style={{ textAlign: 'left', margin: 'auto' , maxWidth: '90vw'}}>
                        {allCounts.map((count) =>
                            <Grid item>
                                {/* ? Arbeitnehmer*/}
                                <h3 className={'section-heading'}>Arbeitnehmer</h3>
                                <div className="performance-list-item">
                                    <p>Bestätigt:</p>
                                    <p>{count.countArbeitnehmer.confirmed === -1 ? "Keine Daten" : count.countArbeitnehmer.confirmed}</p>
                                </div>

                                <div className="performance-list-item">
                                    <p>Nicht Bestätigt:</p>
                                    <p>{count.countArbeitnehmer.notConfirmed === -1 ? "Keine Daten" : count.countArbeitnehmer.notConfirmed}</p>
                                </div>

                                <h4 className={'section-heading'}>Neue Arbeitnehmer</h4>

                                <div className="performance-list-item">
                                    <p>> Gestern ({yesterday}):</p>
                                    <p>{count.countArbeitnehmer.new.yesterday === -1 ? "Keine Daten" : count.countArbeitnehmer.new.yesterday}</p>
                                </div>
                                <div className="performance-list-item">
                                    <p>> Letzte 7 Tage:</p>
                                    <p>{count.countArbeitnehmer.new.lastWeek === -1 ? "Keine Daten" : count.countArbeitnehmer.new.lastWeek}</p>
                                </div>
                                <div className="performance-list-item">
                                    <p>> Letzter Monat:</p>
                                    <p>{count.countArbeitnehmer.new.lastMonth === -1 ? "Keine Daten" : count.countArbeitnehmer.new.lastMonth}</p>
                                </div>

                                <h4 className={'section-heading'}>Gelöschte Arbeitnehmer</h4>
                                <div className="performance-list-item">
                                    <p>> Gestern ({yesterday}):</p>
                                    <p>{count.countArbeitnehmer.deleted.yesterday === -1 ? "Keine Daten" : count.countArbeitnehmer.deleted.yesterday}</p>
                                </div>
                                <div className="performance-list-item">
                                    <p>> Letzte 7 Tage:</p>
                                    <p>{count.countArbeitnehmer.deleted.lastWeek === -1 ? "Keine Daten" : count.countArbeitnehmer.deleted.lastWeek}</p>
                                </div>
                                <div className="performance-list-item">
                                    <p>> Letzter Monat:</p>
                                    <p>{count.countArbeitnehmer.deleted.lastMonth === -1 ? "Keine Daten" : count.countArbeitnehmer.deleted.lastMonth}</p>
                                </div>


                                {/* ? Logins (AN)*/}
                                <h3>Logins (AN)</h3>

                                <div className="performance-list-item">
                                    <p>Logins heute:</p>
                                    <p>{count.countLoginsAN.daily === -1 ? "Keine Daten" : count.countLoginsAN.daily}</p>
                                </div>

                                <div className="performance-list-item">
                                    <p>Logins Monat:</p>
                                    <p>{count.countLoginsAN.monthly === -1 ? "Keine Daten" : count.countLoginsAN.monthly}</p>
                                </div>


                                {/* ? Anonymous Profile's*/}
                                <h3>Anonyme Profile</h3>

                                <div className="performance-list-item">
                                    <p>AN mit anonymen Profil:</p>
                                    <p>{count.countAnonymousProfile.hasProfile === -1 ? "Keine Daten" : count.countAnonymousProfile.hasProfile}</p>
                                </div>

                                <div className="performance-list-item">
                                    <p>AN ohne anonymes Profil:</p>
                                    <p>{count.countAnonymousProfile.noProfile === -1 ? "Keine Daten" : count.countAnonymousProfile.noProfile}</p>
                                </div>

                                <div className="performance-list-item">
                                    <p>Neue, anonyme Profile heute:</p>
                                    <p>{count.countAnonymousProfile.newToday === -1 ? "Keine Daten" : count.countAnonymousProfile.newToday}</p>
                                </div>

                                {/* ? Phone-numbers & Email's*/}
                                <h3>Mobilnummer / Email</h3>

                                <div className="performance-list-item">
                                    <p>Mobilnummern: </p>
                                    <p>{count.countPhoneEmail.phone === -1 ? "Keine Daten" : count.countPhoneEmail.phone}</p>
                                </div>

                                <div className="performance-list-item">
                                    <p>E-mail Adressen:</p>
                                    <p>{count.countPhoneEmail.email === -1 ? "Keine Daten" : count.countPhoneEmail.email}</p>
                                </div>

                                {/* ? AG */}
                                <h3>Arbeitgeber</h3>

                                <div className="performance-list-item">
                                    <p>Arbeitgeber mit aktiven Kontingent:</p>
                                    <p>{count.countAgWithActiveContingent === -1 ? "Keine Daten" : count.countAgWithActiveContingent}</p>
                                </div>

                                <div className="performance-list-item">
                                    <p>Arbeitgeber ohne aktives Kontingent:</p>
                                    <p>{count.countAgWithoutActiveContingent === -1 ? "Keine Daten" : count.countAgWithoutActiveContingent}</p>
                                </div>

                                <div className="performance-list-item">
                                    <p>Stellenanzeigen:</p>
                                    <p>{count.countJobAdvertisements === -1 ? "Keine Daten" : count.countJobAdvertisements}</p>
                                </div>

                                {/* ? Matches */}
                                <h3>Matches</h3>

                                <div className="performance-list-item">
                                    <p>Matches aktuell:</p>
                                    <p>{count.countMatches.activeLeft === -1 ? "Keine Daten" : count.countMatches.activeLeft}</p>
                                </div>

                                <div className="performance-list-item">
                                    <p>Anfragen gesamt: </p>
                                    <p>{count.countMatches.requested === -1 ? "Keine Daten" : count.countMatches.requested}</p>
                                </div>

                                <div className="performance-list-item">
                                    <p>Ablehnungen durch User:</p>
                                    <p>{count.countMatches.rejectedByAn === -1 ? "Keine Daten" : count.countMatches.rejectedByAn}</p>
                                </div>

                                <div className="performance-list-item">
                                    <p>Ablehnungen durch System:</p>
                                    <p>{count.countMatches.rejectedBySystem === -1 ? "Keine Daten" : count.countMatches.rejectedBySystem}</p>
                                </div>

                                <div className="performance-list-item">
                                    <p>Bestätigungen / Chats:</p>
                                    <p>{count.countMatches.confirmed === -1 ? "Keine Daten" : count.countMatches.confirmed}</p>
                                </div>

                                <div className="performance-list-item">
                                    <p>Unvollständige Chats Arbeitnehmer:</p>
                                    <p>{count.countMatches.incompleteChatAn === -1 ? "Keine Daten" : count.countMatches.incompleteChatAn}</p>
                                </div>

                                <div className="performance-list-item">
                                    <p>Unvollständige Chats Arbeitgeber:</p>
                                    <p>{count.countMatches.incompleteChatAg === -1 ? "Keine Daten" : count.countMatches.incompleteChatAg}</p>
                                </div>

                                {/* ? Targets */}
                                <h3>Zielgruppen</h3>

                                <div className="performance-list-item">
                                    <p>Arbeitnehmer:</p>
                                    <p>{count.countTargetGroup.Arbeitnehmer === -1 ? "Keine Daten" : count.countTargetGroup.Arbeitnehmer}</p>
                                </div>

                                <div className="performance-list-item">
                                    <p>Auszubildende:</p>
                                    <p>{count.countTargetGroup.Auszubildende === -1 ? "Keine Daten" : count.countTargetGroup.Auszubildende}</p>
                                </div>

                                <div className="performance-list-item">
                                    <p>Schüler:</p>
                                    <p>{count.countTargetGroup.Schüler === -1 ? "Keine Daten" : count.countTargetGroup.Schüler}</p>
                                </div>

                                <div className="performance-list-item">
                                    <p>Studenten:</p>
                                    <p>{count.countTargetGroup.Studenten === -1 ? "Keine Daten" : count.countTargetGroup.Studenten}</p>
                                </div>

                                <div className="performance-list-item">
                                    <p>Selbstständige:</p>
                                    <p>{count.countTargetGroup.Selbstständige === -1 ? "Keine Daten" : count.countTargetGroup.Selbstständige}</p>
                                </div>

                                <div className="performance-list-item">
                                    <p>Rentner:</p>
                                    <p>{count.countTargetGroup.Rentner === -1 ? "Keine Daten" : count.countTargetGroup.Rentner}</p>
                                </div>

                                {/* ? Branchen*/}
                                <h3>Branchen</h3>


                                {count.countSectors.map((sector) =>
                                    <div style={{ marginTop: '30px' }} >

                                        <h3>{sector.sector}:</h3>
                                        <div>
                                            <p>Davon beschäftigt: {sector.totalWorking}</p>
                                            <p>Davon beschäftigt (%): {sector.percentageWorking}</p>
                                            <p>Interessiert: {sector.totalInterested}</p>
                                            <p>Interessiert (%): {sector.percentageInterested}</p>
                                        </div>
                                        <div className="automated-parent-div">
                                            {sector.countSectorsByTargetGroup.map((targetGroup) =>
                                                <div>
                                                    <h4>{targetGroup.targetGroup}:</h4>
                                                    <p>Davon beschäftigt: {targetGroup.totalWorking}</p>
                                                    <p>Davon beschäftigt (%): {targetGroup.percentageWorking}</p>
                                                    <p>Interessiert: {targetGroup.totalInterested}</p>
                                                    <p>Interessiert (%): {targetGroup.percentageInterested}</p>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                )}


                                {/*<div className="performance-list-item">*/}
                                {/*    <p>IT und Software:</p>*/}
                                {/*    <p>{count.countAnonymousProfilesBySectors.it === -1 ? "Keine Daten" : count.countAnonymousProfilesBySectors.it}</p>*/}
                                {/*</div>*/}
                                {/*<div className="performance-list-item">*/}
                                {/*    <p>Gesundheit:</p>*/}
                                {/*    <p>{count.countAnonymousProfilesBySectors.gesundheit === -1 ? "Keine Daten" : count.countAnonymousProfilesBySectors.gesundheit}</p>*/}
                                {/*</div>*/}
                                {/*<div className="performance-list-item">*/}
                                {/*    <p>Kunst, Design und Kreativität:</p>*/}
                                {/*    <p>{count.countAnonymousProfilesBySectors.kunst === -1 ? "Keine Daten" : count.countAnonymousProfilesBySectors.kunst}</p>*/}
                                {/*</div>*/}
                                {/*<div className="performance-list-item">*/}
                                {/*    <p>Medien und Unterhaltung:</p>*/}
                                {/*    <p>{count.countAnonymousProfilesBySectors.medien === -1 ? "Keine Daten" : count.countAnonymousProfilesBySectors.medien}</p>*/}
                                {/*</div>*/}
                                {/*<div className="performance-list-item">*/}
                                {/*    <p>Management, Marketing und HR:</p>*/}
                                {/*    <p>{count.countAnonymousProfilesBySectors.management === -1 ? "Keine Daten" : count.countAnonymousProfilesBySectors.management}</p>*/}
                                {/*</div>*/}
                                {/*<div className="performance-list-item">*/}
                                {/*    <p>Verkauf, Handel und Vertrieb:</p>*/}
                                {/*    <p>{count.countAnonymousProfilesBySectors.verkauf === -1 ? "Keine Daten" : count.countAnonymousProfilesBySectors.verkauf}</p>*/}
                                {/*</div>*/}
                                {/*<div className="performance-list-item">*/}
                                {/*    <p>Handwerk, Industrie und Technik:</p>*/}
                                {/*    <p>{count.countAnonymousProfilesBySectors.handwerk === -1 ? "Keine Daten" : count.countAnonymousProfilesBySectors.handwerk}</p>*/}
                                {/*</div>*/}
                                {/*<div className="performance-list-item">*/}
                                {/*    <p>Büro und öffentlicher Dienst:</p>*/}
                                {/*    <p>{count.countAnonymousProfilesBySectors.büro === -1 ? "Keine Daten" : count.countAnonymousProfilesBySectors.büro}</p>*/}
                                {/*</div>*/}
                                {/*<div className="performance-list-item">*/}
                                {/*    <p>Finanzen:</p>*/}
                                {/*    <p>{count.countAnonymousProfilesBySectors.finanzen === -1 ? "Keine Daten" : count.countAnonymousProfilesBySectors.finanzen}</p>*/}
                                {/*</div>*/}
                                {/*<div className="performance-list-item">*/}
                                {/*    <p>Soziales, Bildung und Wissen:</p>*/}
                                {/*    <p>{count.countAnonymousProfilesBySectors.soziales === -1 ? "Keine Daten" : count.countAnonymousProfilesBySectors.soziales}</p>*/}
                                {/*</div>*/}
                                {/*<div className="performance-list-item">*/}
                                {/*    <p>Recht:</p>*/}
                                {/*    <p>{count.countAnonymousProfilesBySectors.recht === -1 ? "Keine Daten" : count.countAnonymousProfilesBySectors.recht}</p>*/}
                                {/*</div>*/}
                                {/*<div className="performance-list-item">*/}
                                {/*    <p>Umwelt und Nachhaltigkeit:</p>*/}
                                {/*    <p>{count.countAnonymousProfilesBySectors.umwelt === -1 ? "Keine Daten" : count.countAnonymousProfilesBySectors.umwelt}</p>*/}
                                {/*</div>*/}
                                {/*<div className="performance-list-item">*/}
                                {/*    <p>Tourismus und Freizeit:</p>*/}
                                {/*    <p>{count.countAnonymousProfilesBySectors.tourismus === -1 ? "Keine Daten" : count.countAnonymousProfilesBySectors.tourismus}</p>*/}
                                {/*</div>*/}
                                {/*<div className="performance-list-item">*/}
                                {/*    <p>Automobile:</p>*/}
                                {/*    <p>{count.countAnonymousProfilesBySectors.automobile === -1 ? "Keine Daten" : count.countAnonymousProfilesBySectors.automobile}</p>*/}
                                {/*</div>*/}
                                {/*<div className="performance-list-item">*/}
                                {/*    <p>Logistik:</p>*/}
                                {/*    <p>{count.countAnonymousProfilesBySectors.logistik === -1 ? "Keine Daten" : count.countAnonymousProfilesBySectors.logistik}</p>*/}
                                {/*</div>*/}
                                {/*<div className="performance-list-item">*/}
                                {/*    <p>Sonstige:</p>*/}
                                {/*    <p>{count.countAnonymousProfilesBySectors.sonstige === -1 ? "Keine Daten" : count.countAnonymousProfilesBySectors.sonstige}</p>*/}
                                {/*</div>*/}


                            </Grid>)}

                    </Grid>
                </>}
            </div>
        </React.Fragment>
    );
}

