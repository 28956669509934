import {Select, styled, TextField} from "@mui/material";
import {makeStyles} from "@mui/styles";
import { TextFieldProps } from '@material-ui/core';


let test = undefined;

export const Select2 = (props) => {
    const { showUpDown, ...textFieldProps } = props;
    // const classes = useStyles();



    return (
        // <CustomSelect className={classes.input} {...textFieldProps} />
        <CustomSelect {...textFieldProps} />
    );
}

// const useStyles = makeStyles({
//     input: {
//         '& input[type=number]': {
//             // '-moz-appearance': 'textfield'
//         },
//         '& input[type=number]::-webkit-outer-spin-button': {
//             // '-webkit-appearance': 'none',
//             // margin: 0
//         },
//         '& input[type=number]::-webkit-inner-spin-button': {
//             '-webkit-appearance': 'none',
//         }
//     },
// });



// Do whatever you want with `customProp`
const CustomSelect = styled(Select)(({theme}) => ({
    '& label:not(.MuiInputLabel-shrink)': {
        // top: -8,
        marginLeft: 5,
        fontSize: '14px',
        color: 'black',

        // transition: 'none'
    },
    '& label': {
        margin: 0,
        padding: 0,
        marginLeft: 5,
        top: -4,
        // top: -8,
        fontSize: '14px !important',
        color: 'red',
        transition: theme.transitions.create([
            'color',
            'transform'
        ]),
    },
    '& label.MuiFormLabel-filled:not(.focused)': {
        margin: 0,
        padding: 0,
        marginLeft: 5,
        top: -4,
        fontSize: '14px !important',
        color: 'white',
        transition: theme.transitions.create([
            'color',
            'transform'
        ]),
    },
    '&:hover label': {
        color: 'black',
        fontSize: '14px',
        transition: theme.transitions.create([
            'color',
        ]),
    },
    '& label.Mui-focused': {
        marginLeft: 5,
        top: -4,
        fontSize: '14px !important',
        color: 'white',
        transition: theme.transitions.create([
            'color',
            'transform'
        ]),
    },





    '.MuiFormHelperText-filled': {
        color: 'red',
        transition: theme.transitions.create([
            'color',
            'transform'
        ]),
    },
    '.MuiFormHelperText-contained': {
        color: 'red',
        transition: theme.transitions.create([
            'color',
            'transform'
        ]),
    },


    // '& .MuiInput-underline:after': {
    //     borderColor: theme.palette.primary.main,
    // },
    '& .MuiOutlinedInput-root': {
        background: 'white',
        borderRadius: 25,
        // '& ::placeholder': {
        //     color: theme.palette.secondary.main,
        //     // color: 'red',
        //     opacity: 1,
        //     fontSize: 12,
        //     marginTop: 1,
        // },
        '& .MuiOutlinedInput-input': {
            padding: '10px 10px 10px 10px',
            fontSize: 14,
            borderRadius: 25,
            paddingLeft: 20,
            transition: theme.transitions.create([
                'border-color',
            ]),
        },
        '& fieldset': {
            border: '2px solid',
            borderColor: theme.palette.secondary.main,
            borderRadius: 25,
            transition: theme.transitions.create([
                'border-color',
            ]),
            '& legend': {
                width: 0
            }
        },
        '&:hover fieldset': {
            border: '2px solid',
            borderColor: theme.palette.secondary.main,
            transition: theme.transitions.create([
                'border-color',
            ]),
        },
        '&.Mui-focused fieldset': {
            border: '2px solid',
            borderColor: theme.palette.secondary.main
        },
    },
}));