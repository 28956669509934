import React, {useEffect, useRef, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {useLocation, useParams} from "react-router";
import useFetch from "./UseFetch";
import InfiniteScroll from "react-infinite-scroll-component";
import usePrevious from "./usePrevious";
import {Box, Grid, IconButton, Modal} from "@mui/material";
import Button from "@mui/material/Button";
import {ReactComponent as TrashIcon} from "../images/svg/mulleimer.svg";
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import {useHistory} from "react-router-dom";
import {ReactSession} from "react-client-session";
import {toast} from "react-toastify";
import Vorschau from "./Vorschau";
import Typography from "@mui/material/Typography";
import {stateToHTML} from "draft-js-export-html";
import htmlToDraft from "html-to-draftjs";
import ContentState from "draft-js/lib/ContentState";
import {EditorState} from "draft-js";

export default function StellenanzeigenVerwalten(props) {

    const {locationChange} = props;
    const location = useLocation();
    const params = useParams();
    const api = useFetch();
    const history = useHistory();

    const [vorschau, setVorschau] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMoreData, setHasMoreData] = useState(false);
    const [rows, setRows] = useState([]);
    let temp = [];
    if (ReactSession.get('file_id')) temp = [{id: ReactSession.get('file_id')}];
    const [userImage, setUserImage] = useState(temp);
    const [fileId, setFileId] = useState(false);
    const [teaserImage, setTeaserImage] = useState([]);
    const [title, setTitle] = useState({
        input: '',
        title: ''
    });
    const [subTitle, setSubTitle] = useState('');
    const [slogan, setSlogan] = useState('');
    const [m_w_d, setM_w_d] = useState('');
    const [employmentTypes, setEmploymentTypes] = useState('');
    const [description, setDescription] = useState('');
    const [imageDescription, setImageDescription] = useState('');
    const [editorText, setEditorText] = useState('');
    const [editorTextFromHtml, setEditorTextFromHtml] = useState('');
    const [selectedCompanyAddresses, setSelectedCompanyAddresses] = React.useState([]);
    const [selectedContacts, setSelectedContacts] = React.useState([]);
    const [answeredQuestions, setAnsweredQuestions] = React.useState([]);

    const [badges, setBadges] = useState([
        {text: 'Benefit 1 (optional)', value: ''},
        {text: 'Benefit 2 (optional)', value: ''},
        {text: 'Benefit 3 (optional)', value: ''},
        {text: 'Benefit 4 (optional)', value: ''},
    ]);

    const vorschauStyle = {
        position: 'absolute',
        top: '55%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxHeight: '70%',
        overflow: 'auto',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        zIndex: 4
    };

    useEffect(() => {
        document.title = 'swiitcher';
        locationChange(location, params.token);
        getStellenanzeigen();
    }, [])


    const getStellenanzeigen = async () => {
        let temp = [];
        let hasMore = true;

        for (let i = 1; i < pageNumber + 1; i++) {
            let url = ENV.REACT_APP_API_URL + '/admin/1/job-advertisements?include=creator&page%5Bsize%5D=6&page%5Bnumber%5D=' + i;

            let {response, data} = await api(url);
            if (data && data.data) {
                console.log('page: ' + i);

                data.data.map((item, index) => {
                    item.attributes.creator = data.included.filter(i => i.id == item.attributes.creator_id)[0] ?? null;
                    item.attributes.badges = JSON.parse(item.attributes.badges);

                    if (data && data.included) {
                        item.attributes.contacts = data.included.filter(i => i.type == 'contacts');
                        item.attributes.companyAddresses = data.included.filter(i => i.type == 'company-addresses');
                        item.attributes.answeredQuestions = data.included.filter(i => i.type == 'answered-questions');
                    }

                    if (temp.filter(i => i.id === item.id).length === 0) {
                        temp.push(item);
                    } else {
                        temp[temp.indexOf(temp.filter(i => i.id === item.id)[0])] = item;
                    }
                })

                if (data.meta.pagination.total === temp.length) {
                    hasMore = false;
                }
            }
        }
        console.log(temp);
        setRows(temp);
        setHasMoreData(hasMore);
    }

    const anzeigeFreischalten = async (id, state) => {
        const formData = new FormData();
        formData.append(
            'data',
            JSON.stringify({
                data: {
                    active: state,
                }
            }),
        );

        let {
            response,
            data
        } = await api(ENV.REACT_APP_API_URL + '/job-advertisement/' + id + '/upload/file', 'POST', formData, true)

        console.log(data);

        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            history.push('/');
            return;
        }
        if (response && response.status != '404') {
            let text = state == 1 ? 'freigeschalten' : 'abgelehnt';
            notify('Stellenanzeige wurde ' + text);
            getStellenanzeigen();
        } else if (response.status == '404') {
            notify('Stellenanzeige existiert bereits', true);
        } else {
            notify('API Fehler: ' + response.status, true);
        }
    }

    const getStellenanzeige = async (index) => {

        let url = ENV.REACT_APP_API_URL + '/job-advertisement/' + rows[index].id + '?include=contacts,companyAddresses,answeredQuestions';
        let {response, data} = await api(url)


        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            return;
        }
        console.log(data);
        if (data && data.data) {

            setTitle({title: data.data.attributes.title, input: data.data.attributes.title})
            setSubTitle(data.data.attributes.subTitle)
            setSlogan(data.data.attributes.slogan)
            setM_w_d(data.data.attributes.m_w_d)
            setEmploymentTypes(data.data.attributes.employmentTypes)
            setDescription(data.data.attributes.description)
            setImageDescription(data.data.attributes.imageDescription)

            if (data.data.attributes.file_id) {
                setFileId(data.data.attributes.file_id);
                setTeaserImage([{id: data.data.attributes.file_id}]);
            }


            const contentBlock = htmlToDraft(data.data.attributes.text);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                console.log(contentState)
                const editorState = EditorState.createWithContent(contentState);
                console.log(editorState)
                setEditorTextFromHtml(editorState);

                let options = {
                    blockStyleFn: block => {
                        let data = block.getData();
                        if (data.size == 0) return;

                        let style = {};
                        if (data.get('text-align')) style = {...style, textAlign: data.get('text-align')}
                        return {style};
                    }
                };

                setEditorText(stateToHTML(editorState.getCurrentContent(), options));

            }

            if (data.data.attributes.badges) {
                let badge = [];
                let temp = JSON.parse(data.data.attributes.badges);
                temp.map((item, index) => {
                    badge.push(item);
                })
                setBadges(badge);
            }

            if (data.included) {
                console.log(data.included.filter(i => i.type == 'contacts'))
                setSelectedContacts(data.included.filter(i => i.type == 'contacts'));

                setSelectedCompanyAddresses(data.included.filter(i => i.type == 'company-addresses'));

                setAnsweredQuestions(data.included.filter(i => i.type == 'answered-questions'));
            }
            setLoaded(true);

        } else if (response.error) {
            notify('API Fehler: ' + response.status, true);
        }
    }

    const notify = (message, error) => {
        console.log('NOTIFY')
        if (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        } else {
            toast.success(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        }
    }

    const getVorschau = (index) => {
        console.log(index);
        if (vorschau >= 0 && rows[vorschau]) {
            setTitle({title: rows[vorschau].attributes.title, input: rows[vorschau].attributes.title})
            setSubTitle(rows[vorschau].attributes.subTitle)
            setSlogan(rows[vorschau].attributes.slogan)
            setM_w_d(rows[vorschau].attributes.m_w_d)
            setEmploymentTypes(rows[vorschau].attributes.employmentTypes)
            setDescription(rows[vorschau].attributes.description)
            setImageDescription(rows[vorschau].attributes.imageDescription)

            if (rows[vorschau].attributes.file_id) {
                setFileId(rows[vorschau].attributes.file_id);
                setTeaserImage([{id: rows[vorschau].attributes.file_id}]);
            }


            const contentBlock = htmlToDraft(rows[vorschau].attributes.text);
            console.log(contentBlock)
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                console.log(contentState)
                const editorState = EditorState.createWithContent(contentState);
                console.log(editorState)
                setEditorTextFromHtml(editorState);

                let options = {
                    blockStyleFn: block => {
                        let data = block.getData();
                        if (data.size == 0) return;

                        let style = {};
                        if (data.get('text-align')) style = {...style, textAlign: data.get('text-align')}
                        return {style};
                    }
                };

                setEditorText(stateToHTML(editorState.getCurrentContent(), options));

            }

            setSelectedContacts(rows[vorschau].attributes.contacts);
            setSelectedCompanyAddresses(rows[vorschau].attributes.companyAddresses);
            setAnsweredQuestions(rows[vorschau].attributes.answeredQuestions);
            setLoaded(true);
        }
    }

    const newData = () => {
        console.log('newData')
        if (hasMoreData === true) {
            let pageN = pageNumber;
            let temp = ++pageN;
            setPageNumber(temp);
        }
    }

    useEffect(() => {

        if (pageNumber > 1) {
            console.log('useEffect pageNumber')
            getStellenanzeigen()
        }
    }, [pageNumber])

    return (
        <React.Fragment>
            <div
                id='top-container'
                style={{
                    width: '100% +1px',
                    minHeight: 'calc(100vh - 123px)',
                    height: 'auto',
                    marginTop: 64,
                    marginLeft: '-1px',
                    position: 'relative',
                }}
            >
                <div style={{padding: 50}}>
                    <InfiniteScroll
                        dataLength={rows ? rows.length : 0} //This is important field to render the next data
                        next={newData}
                        hasMore={hasMoreData}
                        loader={<h4>Loading...</h4>}
                        endMessage={
                            <p style={{textAlign: 'center'}}>
                                <b>No more items to load</b>
                            </p>
                        }
                        style={{overflow: 'unset'}}
                    >
                        {rows && rows.map((item, index) => {
                            return <>
                                <Grid key={index} item xs={12} style={{paddingBottom: 20}}>
                                    <div style={{
                                        minHeight: 'calc(100vh/2 - 130px)',
                                        maxHeight: 'fit-content',
                                        background: '#dddddd',
                                        border: item.attributes.active === 1 ? '3px solid transparent' : 'unset',
                                        padding: 10,
                                        borderRadius: 20,
                                        position: 'relative'
                                    }}>
                                        <p className={'headerName'}
                                           style={{position: 'relative', width: 'fit-content', marginBottom: 3}}>
                                            {item.attributes.title}
                                        </p>
                                        <p style={{
                                            textAlign: 'left',
                                            marginTop: 0,
                                            marginLeft: 5
                                        }}>{item.attributes.subTitle}</p>
                                        <p style={{
                                            textAlign: 'left',
                                            marginTop: 25,
                                            marginBottom: 0,
                                            marginLeft: 5
                                        }}>{item.attributes.slogan}</p>
                                        <div style={{textAlign: 'left', marginTop: 15, marginLeft: 5}}>
                                            {(item.attributes.badges && Array.isArray(item.attributes.badges)) && item.attributes.badges.map((item, index) => {
                                                return (item.value === '' ? null : <span key={index} style={{
                                                    fontSize: 14,
                                                    // background: '#eee',
                                                    border: '2px solid #000',
                                                    padding: '7px 40px 5px',
                                                    borderRadius: 25,
                                                    marginRight: 15
                                                }}>{item.value}</span>);
                                            })}
                                        </div>
                                        <div style={{
                                            marginTop: 20,
                                            paddingLeft: 5,
                                            textAlign: 'left',
                                            marginBottom: 40,
                                            maxWidth: 300
                                        }}>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <p style={{margin: 0}}>Kunden-ID:</p>
                                                    <p style={{margin: 0}}>CompanyName:</p>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <p style={{margin: 0}}>{item.attributes.creator.id}</p>
                                                    <p style={{margin: 0}}>{item.attributes.creator.attributes.companyName}</p>
                                                </Grid>
                                            </Grid>


                                        </div>
                                        <Button style={{
                                            position: 'absolute',
                                            bottom: 10,
                                            right: 10,
                                            pointer: 'none',
                                            fontFamily: 'VagRoundedBold',
                                            textDecoration: 'underline',
                                            color: '#00374e'
                                        }}
                                                onClick={() => {
                                                    anzeigeFreischalten(item.id, 1);
                                                }}
                                        >Freischalten</Button>
                                        <Button style={{
                                            position: 'absolute',
                                            bottom: 10,
                                            left: 10,
                                            pointer: 'none',
                                            fontFamily: 'VagRoundedBold',
                                            textDecoration: 'underline',
                                            color: '#00374e'
                                        }}
                                                onClick={() => {
                                                    anzeigeFreischalten(item.id, 0);
                                                }}
                                        >Ablehnen</Button>

                                        <Button style={{
                                            position: 'absolute',
                                            top: 10,
                                            right: 10,
                                            pointer: 'none',
                                            fontFamily: 'VagRoundedBold',
                                            textDecoration: 'underline',
                                            color: '#00374e'
                                        }}
                                                onClick={() => {
                                                    console.log(index);
                                                    getStellenanzeige(index);
                                                }}
                                        >Vorschau</Button>
                                    </div>
                                </Grid>
                            </>
                        })}
                    </InfiniteScroll>
                </div>
            </div>
            <Modal
                open={(vorschau >= 0 && loaded) ? true : false}
                onClose={() => {
                    setVorschau(false)
                    setLoaded(false)
                    setTitle({title: '', input: ''})
                    setSubTitle('')
                    setSlogan('')
                    setM_w_d('');
                    setEmploymentTypes('')
                    setDescription('')
                    setImageDescription('')
                    setFileId('');
                    setTeaserImage([]);
                    setEditorTextFromHtml('');
                    setEditorText('');
                    setBadges([]);
                    setSelectedContacts([]);
                    setSelectedCompanyAddresses([]);
                    setAnsweredQuestions([]);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={vorschauStyle}>
                    {(vorschau >= 0 && loaded) ?
                        <Vorschau title={title}
                                  subTitle={subTitle}
                                  description={description}
                                  imageDescription={imageDescription}
                                  slogan={slogan}
                                  m_w_d={m_w_d}
                                  employmentTypes={employmentTypes}
                                  editorText={editorText}
                                  companyImage={userImage ? userImage[0] : null}
                                  teaserImage={teaserImage ? teaserImage[0] : null}
                                  selectedCompanyAddresses={selectedCompanyAddresses}
                                  selectedContacts={selectedContacts}
                                  badges={badges}
                        />
                        : null}
                </Box>
            </Modal>
        </React.Fragment>
    );
}

