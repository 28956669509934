import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import {
    Box, Checkbox, FormControl, FormControlLabel, FormGroup,
    IconButton, InputAdornment, InputBase, InputLabel,
    Link, MenuItem, Modal, Select,
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {ReactComponent as LoginSvg} from './images/svg/Login.svg';
import {ReactComponent as LogoSvg} from './images/svg/swiitcher_logo.svg';
import {ReactComponent as LogoSvgWhite} from './images/svg/swiitcher_logo_weiss.svg';
import {useHistory} from "react-router-dom";
import {CssTextField} from "./components/CssTextField";
import {ReactSession} from "react-client-session";
import LoginIcon from '@mui/icons-material/Login';
import PersonIcon from '@mui/icons-material/Person';
import MenuCloseIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import useFetch from "./components/UseFetch";
import {CssTextField3} from "./components/CssTextField3";
import {Select2} from "./components/Select2";
import {styled} from "@mui/styles";
import {CustomButton} from "./components/CustomButton";
import Typography from "@mui/material/Typography";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import {PriorityHigh, Visibility, VisibilityOff} from "@mui/icons-material";

const BootstrapInput = styled(InputBase)(({theme}) => ({
    'label + &': {
        // marginTop: theme.spacing(3),

    },
    'label': {
        margin: 0,
        padding: 0,
        marginLeft: 5,
        top: -14,
        // top: -8,
        fontSize: '12px !important',
        color: 'red',
        // transition: theme.transitions.create([
        //     'color',
        //     'transform'
        // ]),
    },
    '& .MuiInputBase-input': {
        borderRadius: 25,
        position: 'relative',
        // backgroundColor: theme.palette.background.paper,
        border: '2px solid #C7D300',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        // transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        // '&:focus': {
        //     borderRadius: 4,
        //     borderColor: '#80bdff',
        //     boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        // },
    },
}));

const MenuAppBar = forwardRef((props, ref) => {
        const {logiin, getHistory, overAllCount, menuState, userT, update, notify, location, changeMenuState} = props;
        const history = useHistory();
        const api = useFetch();

        //const [location, setLocation] = React.useState(history.location.pathname);
        const [isArbeitnehmer, setIsArbeitnehmer] = React.useState(ReactSession.get('login') ?? true);

        const passwordStyle = {
            position: 'absolute',
            top: '55%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '20%',
            minWidth: 200,
            maxHeight: '70%',
            overflow: 'auto',
            backgroundColor: 'rgba(218, 227, 243, 0.95)',
            border: '2px solid transparent !important',
            borderRadius: 5,
            padding: '5px 16px 20px',
            boxShadow: 24,
            zIndex: 4,
            lineHeight: '13px'
        };

        useImperativeHandle(ref, () => ({

            logOut() {
                logout();
            },
            logIn(mail, password) {
                let e = login(mail, password).then((e) => {
                    return e;
                })
                return e;
            },
            reg(mail, accountType, password, passwordVerify) {
                let e = register(mail, accountType, password, passwordVerify).then((e) => {
                    return e;
                })
                return e;
            }

        }));


        useEffect(() => {
            if (history) getHistory(history);
        }, [history])

        const [onPasswordForget, setOnPasswordForget] = React.useState(false);
        const [onPasswordReset, setOnPasswordReset] = React.useState(false);
        const [anchorElNav, setAnchorElNav] = React.useState(false);
        const [anchorElUser, setAnchorElUser] = React.useState(false);
        const [user, setUser] = React.useState(null);
        const [loginOverlay, setLoginOverlay] = React.useState(false);
        const [mail, setMail] = React.useState('');
        const [mailError, setMailError] = React.useState(false);
        const [mailPasswordForget, setMailPasswordForget] = React.useState('');
        const [mailPasswordForgetError, setMailPasswordForgetError] = React.useState(false);

        const [mailPassword1Reset, setMailPassword1Reset] = React.useState('');
        const [mailPassword1ResetError, setMailPassword1ResetError] = React.useState(false);

        const [mailPassword2Reset, setMailPassword2Reset] = React.useState('');
        const [mailPassword2ResetError, setMailPassword2ResetError] = React.useState(false);

        const [showPassword1, setShowPassword1] = useState(false);
        const [showPassword2, setShowPassword2] = useState(false);
        const handleClickShowPassword1 = () => setShowPassword1(!showPassword1);
        const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
        const handleMouseDownPassword1 = () => setShowPassword1(!showPassword1);
        const handleMouseDownPassword2 = () => setShowPassword2(!showPassword2);
        // const [username, setUsername] = React.useState('');
        // const [usernameError, setUsernameError] = React.useState(false);
        const [password, setPassword] = React.useState('');
        const [passwordError, setPasswordError] = React.useState(false);
        const [passwordVerify, setPasswordVerify] = React.useState('');
        const [passwordVerifyError, setPasswordVerifyError] = React.useState(false);
        const [onLogin, setOnLogin] = React.useState(false);
        const [onRegistration, setOnRegistration] = React.useState(false);
        const [buttonHover, setButtonHover] = useState('');
        const [accountType, setAccountType] = useState(false);


        const [isShrink, setIsShrink] = useState([]);
        const [isShrinkPasswordForget, setIsShrinkPasswordForget] = useState([]);
        const [isShrinkPassword1Reset, setIsShrinkPassword1Reset] = useState([]);
        const [isShrinkPassword2Reset, setIsShrinkPassword2Reset] = useState([]);

        useEffect(() => {
            if (location !== history.location.pathname) {
                if ((history.location.pathname != '/arbeitgeber' && ReactSession.get('login') == 'arbeitnehmer') ||
                    (history.location.pathname == '/' && !ReactSession.get('login'))) {
                    setIsArbeitnehmer(true);
                } else if ((ReactSession.get('login') == 'arbeitgeber' || (history.location.pathname == '/arbeitgeber' && !ReactSession.get('login')))) {
                    setIsArbeitnehmer(false);
                }
            }

            if (location.token) {
                setOnPasswordReset(true);
            }
        }, [location]);

        useEffect(() => {
            setUser(userT)
        }, [userT])

        useEffect(() => {
            if (user) {
                console.log(user);
                ReactSession.set('user', user);
            }
        }, [user])

        const handleOpenNavMenu = (event) => {
            setAnchorElNav(event.currentTarget);
        };
        const handleOpenUserMenu = (event) => {
            setAnchorElUser(event.currentTarget);
        };

        const handleCloseUserMenu = () => {
            setAnchorElUser(false);
        };
        const onNavigate = async (page) => {

        };
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            disableScrollLock: true,
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    minHeight: 'unset',
                },
            },
        };

        useEffect(() => {
            document.title = 'swiitcher';
        }, [])

        // const handleMouseIn = (type) => {
        //     switch (type) {
        //         case 'loginButton':
        //             setLoginButtonHover(true);
        //             break;
        //         case 'login2Button':
        //             setLogin2ButtonHover(true);
        //             break;
        //     }
        // };
        //
        // const handleMouseOut = (type) => {
        //     switch (type) {
        //         case 'loginButton':
        //             setLoginButtonHover(false);
        //             break;
        //         case 'login2Button':
        //             setLogin2ButtonHover(false);
        //             break;
        //     }
        // };

        const login = async (mailT, passwordT) => {
            console.log('login');
            setMailError(false);
            setPasswordError(false);
            if (validate(mailT, passwordT)) {
                setOnLogin(true);

                // if (username != 'arbeitnehmer' && username != 'arbeitgeber') {
                //     setUsernameError('Benutzer existiert nicht');
                // } else if ((username == 'arbeitnehmer' && password != 'arbeitnehmer') || (username == 'arbeitgeber' && password != 'arbeitgeber')) {
                //     setPasswordError('Passwort ist fehlerhaft');
                // } else {

                //ReactSession.set('login', username);

                //if ((history.location.pathname == '/' && username != 'arbeitgeber') || (history.location.pathname == '/arbeitgeber' && username == 'arbeitnehmer')) {

                // setTimeout(() => {
                //     if (username == 'arbeitnehmer') {
                //         setIsArbeitnehmer(true);
                //         history.push('/übersicht');
                //         setUser('arbeitnehmer');
                //     } else {
                //         setIsArbeitnehmer(false);
                //         history.push('/übersicht');
                //         setUser('arbeitgeber');
                //     }
                //     update(username);
                //     notify('Du wurdest erfolgreich eingeloggt');
                // }, 200)

                let ma = mailT ?? mail;
                let pass = passwordT ?? password;

                let url = ENV.REACT_APP_API_URL + '/user/login';
                let {response, data} = await api(url, 'POST',
                    JSON.stringify({
                        email: ma.trim(),
                        password: pass.trim()
                    })).catch((error) => {
                    console.log(error);
                    return {
                        response: {
                            status: error.message,
                            error: true
                        },
                        data: null
                    }
                });
                let temp = false;
                console.log(data)

                if (response && data) {

                    if (data.errors && data.errors[0] && data.errors[0].status == '403' && data.errors[0].detail == "User does not exist") {
                        setMailError('Benutzer existiert nicht');
                    } else if (data.errors && data.errors[0] && data.errors[0].status == '403' && data.errors[0].detail == "User password is wrong") {
                        setPasswordError('Passwort ist fehlerhaft');
                    } else if (data.errors && data.errors[0] && data.errors[0].status == '403' && data.errors[0].detail == "User not confirmed") {
                        setMailError((
                            <span>Benutzer ist nicht bestätigt - <Link style={{cursor: 'pointer'}} onClick={() => {
                                register();
                            }}>erneut versenden</Link></span>));
                    } else if (data.errors && data.errors[0]) {
                        notify('API Fehler: ' + response.status, true);
                    } else if (data.data && data.data.user && !data.data.user.admin) {
                        notify('Keine ausreichenden Berechtigungen', true);
                    } else if (data.data && data.data.token && data.data.user.admin === 1) {
                        console.log('right credentials');
                        ReactSession.set('login', mail);
                        ReactSession.set('token', data.data.token);
                        ReactSession.set('refreshToken', data.data.refreshToken);
                        ReactSession.set('userId', data.data.user.id);
                        console.log(data.data.user.userType);
                        ReactSession.set('user', data.data.user.userType);
                        ReactSession.set('admin', true);
                        if (data.data.jobAdvertisementContingent) ReactSession.set('jobAdvertisementContingent', data.data.jobAdvertisementContingent);
                        ReactSession.set('fileId', data.data.user.file_id);
                        ReactSession.set('username', data.data.user.username);
                        temp = true;
                        console.log(data.data);

                        setIsArbeitnehmer(false);
                        setUser('arbeitgeber');
                        update('arbeitgeber');



                        notify('Du wurdest erfolgreich eingeloggt');

                        //history.push('/');

                        //sessionGotUpdate();
                        //return;
                        setLoginOverlay(false);
                        // setUsername('');
                        setPassword('');
                        // setUsernameError(false);
                        setPasswordError(false);
                    }

                    if (!mailT && !passwordT && false) {
                        //history.push('/übersicht');
                    } else {
                        //if (temp) history.push('/übersicht');
                        return {mError: mailError, pError: passwordError, user: data.data?.user, success: temp}
                    }

                } else if (response.error) {
                    notify('API Fehler: ' + response.status, true);
                }

                setOnLogin(false);


            } else {
                let mError = '';
                let pError = '';
                if (mailT === '') {
                    mError = 'E-Mail darf nicht leer sein!';
                }
                if (!passwordT || passwordT !== passwordT.trim()) {
                    pError = 'Passwort enthält Leerzeichen!';
                }
                if (passwordT === '') {
                    pError = 'Passwort darf nicht leer sein!';
                }

                return {mError, pError, success: false}
            }

        };

        const validate = (mailT, passwordT) => {

            let ma = mailT ?? mail;
            let pass = passwordT ?? password;

            let error = false;
            if (ma === '') {
                error = true;
                setMailError('E-Mail darf nicht leer sein!');
            } else {
                setMailError(undefined);
            }
            if (pass === '') {
                error = true;
                setPasswordError('Passwort darf nicht leer sein!');
            } else {
                setPasswordError(undefined);
            }
            if (!pass || pass !== pass.trim()) {
                error = true;
                setPasswordError('Passwort enthält Leerzeichen!');
            }
            return !error;
        };

        const handleKeypress = e => {
            //it triggers by pressing the enter key
            if (e.charCode === 13) {
                login();
            }
        };

        const logout = async () => {
            setLoginOverlay(false);
            console.log('LOGOUT')
            const {response,data} = await api(ENV.REACT_APP_API_URL + '/user/logout', 'POST', JSON.stringify({refreshToken: ReactSession.get('token')}));

            console.log(data);

            if (data.data.status == 'logout') {
                ReactSession.set('user', undefined);
                ReactSession.set('login', undefined);
                ReactSession.set('token', undefined);
                if (ReactSession.get('user') == 'arbeitnehmer') {
                    setIsArbeitnehmer(true);
                    history.push('/');
                } else if (ReactSession.get('user') == 'arbeitgeber') {
                    setIsArbeitnehmer(false);
                }
                setUser(false);
                update(null);
                notify('Du wurdest erfolgreich ausgeloggt');
            }

        }

        useEffect(() => {
            setMailError(false);
            // setUsernameError(false);
            setPasswordError(false);
            setPasswordVerifyError(false);
        }, [onRegistration])

        const register = async (mailT, accountTypeT, passwordT, passwordVerifyT) => {
            console.log('register');

            let ma = mailT ?? mail;
            // let us = usernameT ?? username;
            let at = accountTypeT ?? accountType;
            let pa = passwordT ?? password;
            let paV = passwordVerifyT ?? passwordVerify;


            console.log(ma);
            // console.log(us);
            console.log(pa);
            console.log(paV);
            let temp = false;
            let error = false;
            let errorType = false;

            if (
                validateRegistration(false, ma, pa, paV) || true
            ) {
                console.log('ALARM')
                // setOnRegistration(true);

                let url = ENV.REACT_APP_API_URL + '/user/register';
                const {response, data} = await api(url, 'POST', JSON.stringify({
                    data: {
                        attributes: {
                            email: ma.trim(),
                            // username: us.trim(),
                            userType: at ? 'Arbeitnehmer' : 'Arbeitgeber',
                            password: pa.trim(),

                        }
                    }
                }));
                console.log(data);
                if (data && data.errors && data.errors[0] && data.errors[0].status == '401') {
                    console.log('wrong credentials');
                } else if (data && data.errors && data.errors[0] && data.errors[0].meta && data.errors[0].meta.email) {
                    error = data.errors[0].meta.email;
                    errorType = 'mail';
                    setMailError(data.errors[0].meta.email);
                    // setMailError('E-Mail darf nicht leer sein!');
                } else if (data && data.errors && data.errors[0] && data.errors[0].meta && data.errors[0].meta.password) {
                    error = data.errors[0].meta.password;
                    errorType = 'password';
                    setPasswordError(data.errors[0].meta.password);
                } else if (data && data.errors && data.errors[0] && data.errors[0].meta && data.errors[0].meta.username) {
                    error = data.errors[0].meta.username;
                    errorType = 'mail';
                    setMailError(data.errors[0].meta.username);
                } else if (response.error) {
                    notify('API Fehler: ' + response.status, true);
                } else if (data &&
                    data.errors &&
                    data.errors[0] &&
                    data.errors[0].status == '400'
                ) {
                    console.log('bad');
                } else if ((data.errors && data.errors[0]) || response.status == '500') {
                    notify('API Fehler: ' + response.status, true);
                } else if (data.data && data.data.id) {
                    console.log('right credentials');
                    temp = true;
                    // history.push('/login');
                    notify('Registrierung erfolgreich - Dir wurde eine E-Mail zugesendet. Bitte klicke auf den Link in der E-Mail um die Registrierung abzuschließen');
                    //setRegistration(false);
                    //setActiveStep(2);
                }


                // setOnRegistration(false)
            }
            if (mailT !== undefined && passwordT !== undefined && passwordVerifyT !== undefined) {


                return {
                    mError: mailError,
                    pError: passwordError,
                    pVError: passwordVerifyError,
                    success: temp,
                    error,
                    errorType
                }
            }
        };

        const validateRegistration = (send = false, mailT, passwordT, passwordVerifyT) => {
            let error = false;

            let ma = mailT ?? mail;
            // let us = usernameT ?? username;
            let pa = passwordT ?? password;
            let paV = passwordVerifyT ?? passwordVerify;

            // if (us == '' || (send && !us)) {
            //     error = true;
            //     setUsernameError('Benutzername darf nicht leer sein!');
            // } else {
            //     setUsernameError(undefined);
            // }
            if (pa == '' || (send && !pa)) {
                error = true;
                setPasswordError('Passwort darf nicht leer sein!');
            } else {
                setPasswordError(undefined);
            }

            if (ma == '' || (send && !ma)) {
                error = true;
                setMailError('E-Mail darf nicht leer sein!');
            } else if (ma && !validateEmail(ma)) {
                error = true;
                setMailError('Keine gültige E-Mail!');
            } else {
                setMailError(undefined);
            }
            if (paV == '' || (send && !paV)) {
                error = true;
                setPasswordVerifyError('Passwort darf nicht leer sein!');
            } else {
                setPasswordVerifyError(undefined);
            }

            if (pa !== pa.trim() || paV !== paV.trim()) {
                error = true;
                setPasswordError('Passwörter enthalten Leerzeichen!');
                setPasswordVerifyError('Passwörter enthalten Leerzeichen!');
            } else if (pa.trim() !== paV.trim()) {
                error = true;
                setPasswordError('Passwörter stimmen nicht überein!');
                setPasswordVerifyError('Passwörter stimmen nicht überein!');
            }
            return !error;
        };

        const validateEmail = email => {
            return String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                );
        };

        const requestNewPassword = async () => {
            console.log('requestNewPassword');

            if (validatePasswortForget()) {
                let updatedAttributes = {
                    email: mailPasswordForget.trim()
                }

                let url = ENV.REACT_APP_API_URL + '/user/password-forgotten';
                const {response, data} = await api(url, 'POST', JSON.stringify(updatedAttributes));

                if (response && data && data.data && data.data.id) {
                    ReactSession.set('userId', undefined);
                    ReactSession.set('token', undefined);
                    ReactSession.set('refreshToken', undefined);
                    //history.push('/');
                    setOnPasswordForget(false);
                    notify('Dir wurde eine E-Mail zugesendet. Bitte klicke auf den Link in der E-Mail um dein Passwort zu ändern');
                } else if (data && data.errors && data.errors[0] && data.errors[0].status == 403) {
                    setMailPasswordForgetError("Unbekannte E-Mail-Adresse");
                } else if (data && data.errors && data.errors[0] && data.errors[0].status == 400) {
                    setMailPasswordForgetError("Fehlerhafte E-Mail-Adresse");
                } else if ((data && data.errors && data.errors[0]) || response.error) {
                    notify('API Fehler: ' + response.status, true);
                }
            }

        };

        const updatePassword = async () => {
            console.log('requestNewPassword');


            if (validateNewPassword() && location.token) {

                let updatedAttributes = {
                    password: mailPassword1Reset,
                    token: location.token
                };

                let url = ENV.REACT_APP_API_URL + '/user/new-password';
                const {response, data} = await api(url, 'POST', JSON.stringify(updatedAttributes));

                if (response && response.status != '401' && data) {
                    ReactSession.set('newPassword', undefined);
                    ReactSession.set('token', undefined);
                    ReactSession.set('refreshToken', undefined);
                    setOnPasswordReset(false);
                    notify('Dein Passwort wurde erfolgreich aktualisiert');
                } else if ((data.errors && data.errors[0]) && response.status == '401') {
                    notify('Dieser Token ist nicht mehr gültig', true);
                } else if ((data.errors && data.errors[0]) || response.status == '500') {
                    notify('API Fehler: ' + response.status, true);
                }
            }
        };

        const validateNewPassword = () => {
            let error = false;


            let lowerCaseLetters = /[a-z]/g;
            if(!mailPassword1Reset.match(lowerCaseLetters)) {
                error = 'Passwort enthält keinen Kleinbuchstaben';
            }

            // Validate capital letters
            let upperCaseLetters = /[A-Z]/g;
            if(!mailPassword1Reset.match(upperCaseLetters)) {
                error = 'Passwort enthält keinen Großbuchstaben';
            }

            // Validate numbers
            let numbers = /[0-9]/g;
            if(!mailPassword1Reset.match(numbers)) {
                error = 'Passwort enthält keine Zahl';
            }

            // Validate special chars
            let chars = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            if(!mailPassword1Reset.match(chars)) {
                error = 'Passwort enthält kein Sonderzeichen';
            }

            // Validate length
            if(mailPassword1Reset.length < 8) {
                console.log('to short')
                error = 'Passwort muss mindestens 8 Zeichen lang sein'
            }

            // Validate length
            if(mailPassword1Reset.length === '') {
                error = 'Passwort darf nicht leer sein!'
            }

            if (mailPassword1Reset.trim() !== mailPassword2Reset.trim()) {
                error = true;
                setMailPassword1ResetError('Passwörter stimmen nicht überein!');
                setMailPassword2ResetError('Passwörter stimmen nicht überein!');
            } else {
                setMailPassword1ResetError(error)
                setMailPassword2ResetError(undefined)
            }

            if (!error) {
                setMailPassword1ResetError(undefined);
                setMailPassword2ResetError(undefined);
            }

            return !error;

        };


        const validatePasswortForget = (send = false) => {
            var error = false;
            if (mailPasswordForget === '' || (send && !mailPasswordForget)) {
                setMailPasswordForgetError('E-Mail darf nicht leer sein!');
                error = true;
            } else if (!validateEmail(mailPasswordForget)) {
                setMailPasswordForgetError('Fehlerhafte E-Mail-Adresse');
                error = true;
            } else {
                setMailPasswordForgetError(undefined);
            }

            return !error;

        };

        return (
            <div style={{}}>
                <AppBar position="static"
                        style={{fontFamily: 'VagRoundedBold', background: 'white'}}>
                    <Container maxWidth="xxl">
                        <Toolbar disableGutters>
                            <LogoSvg
                                style={{cursor: 'pointer', width: 210,}}
                                id={'logoHeader'}
                                onClick={() => {
                                    history.push('/')
                                }}
                            />
                            <Box sx={{flexGrow: 1}}>
                            </Box>
                            <IconButton style={{padding: 0, transition: 'all 0.2s ease-in-out'}} onClick={() => {
                                if (!user) {
                                    if (loginOverlay) {
                                        setButtonHover(false);
                                    }
                                    setLoginOverlay(!loginOverlay);
                                } else {
                                    changeMenuState();
                                }
                            }}>
                                {!user && <LoginIcon
                                    onMouseOver={() => setButtonHover('login2Button')}
                                    onMouseOut={() => setButtonHover('')}
                                    className={('TestF ') + ((buttonHover == 'login2Button' || loginOverlay) ? 'blue' : 'yellow')}
                                    style={{
                                        transition: 'all 0.2s ease-in-out',
                                        width: 25,
                                        height: 25,
                                        cursor: 'pointer',
                                        //color: login2ButtonHover ? '#C7D300' : '#00374e',
                                        //border: '2px solid ' + (login2ButtonHover ? '#C7D300' : '#00374e'),
                                        borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                        padding: 1
                                    }}
                                />}

                                <div style={{display: 'flex', position: 'relative'}}>
                                    {(user && !menuState) && <MenuCloseIcon
                                        // color={login2ButtonHover ? '#C7D300' : '#00374e'}
                                        //className={(isArbeitnehmer ? 'loginSvgWhite ' : 'loginSvg ') + (login2ButtonHover ? 'yellow' : 'blue') }
                                        className={'TestF ' + ((buttonHover == 'login2Button' || menuState) ? 'blue' : 'yellow')}
                                        onMouseOver={() => setButtonHover('login2Button')}
                                        onMouseOut={() => setButtonHover('')}
                                        style={{
                                            transition: 'all 0.2s ease-in-out',
                                            width: 25,
                                            height: 25,
                                            cursor: 'pointer',
                                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                            padding: 1
                                        }}
                                    />}
                                    {(user && menuState) && <MenuOpenIcon
                                        // color={login2ButtonHover ? '#C7D300' : '#00374e'}
                                        //className={(isArbeitnehmer ? 'loginSvgWhite ' : 'loginSvg ') + (login2ButtonHover ? 'yellow' : 'blue') }
                                        className={'TestF ' + ((buttonHover == 'login2Button' || menuState) ? 'blue' : 'yellow')}
                                        onMouseOver={() => setButtonHover('login2Button')}
                                        onMouseOut={() => setButtonHover('')}
                                        style={{
                                            transition: 'all 0.2s ease-in-out',
                                            width: 25,
                                            height: 25,
                                            cursor: 'pointer',
                                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                            padding: 1
                                        }}
                                    />}
                                    {(user && !menuState && overAllCount && overAllCount > 0) ?
                                        <div className={'count'} style={{
                                            fontFamily: 'VagRoundedBold',
                                            top: -18,
                                            right: -20
                                        }}>{overAllCount}</div> : null}

                                </div>
                            </IconButton>

                            {!user && <div style={{
                                position: 'absolute',
                                top: 68,
                                right: 0,
                                borderTopLeftRadius: 4,
                                borderTopRightRadius: 4,
                                borderBottomLeftRadius: 25,
                                borderBottomRightRadius: 25,
                                boxShadow: '0px 0px 4px 3px rgba(0,0,0,0.5)',
                                width: 230,
                                //height: onRegistration ? 445 : 245,
                                paddingBottom: 15,
                                background: 'white',
                                opacity: loginOverlay ? 1 : 0,
                                display: loginOverlay ? 'block' : 'none',
                                transition: 'all 0.05s ease-in-out'
                            }}>
                                {(!onRegistration) && <div style={{padding: '15px 15px 15px 15px'}}>
                                    <CssTextField3
                                        fieldtype={'ASDF'}
                                        label="E-Mail"
                                        style={{
                                            transition: 'all 0.2s ease',
                                            // margin: '0 0 8px 0',
                                            // minHeight: 60,
                                            marginTop: (isShrink && isShrink.includes('E-Mail') || mail) ? 20 : 5,
                                            width: '100%'
                                        }}
                                        onFocus={() => {
                                            let t = [...isShrink];
                                            t.push('E-Mail');
                                            setIsShrink(t);
                                        }}
                                        onBlur={() => {
                                            setIsShrink([]);
                                        }}
                                        autoComplete={'off'}
                                        onChange={e => {
                                            if (e.target.value.trim().length === 0) {
                                                setMailError(true);
                                            } else {
                                                setMailError(false);
                                            }
                                            setMail(e.target.value);
                                        }}
                                        className={'text-input-label-blue'}
                                        onKeyPress={handleKeypress}
                                        value={mail}
                                        error={!!mailError}
                                        helperText={mailError === true ? 'Benutzername darf nicht leer sein!' : mailError}
                                    />
                                    <CssTextField3
                                        label="Passwort"
                                        type="password"
                                        style={{
                                            transition: 'all 0.2s ease',
                                            marginTop: (isShrink && isShrink.includes('Passwort') || password) ? 25 : 5,
                                            marginBottom: 10,
                                            width: '100%'
                                        }}
                                        onFocus={() => {
                                            setIsShrink(['Passwort']);
                                        }}
                                        onBlur={() => {
                                            setIsShrink([]);
                                        }}
                                        onChange={e => {
                                            if (e.target.value.trim().length === 0) {
                                                setPasswordError(true);
                                            } else {
                                                setPasswordError(false);
                                            }
                                            setPassword(e.target.value);
                                        }}
                                        className={'text-input-label-blue'}
                                        onKeyPress={handleKeypress}
                                        value={password}
                                        error={!!passwordError}
                                        helperText={passwordError === true ? 'Passwort darf nicht leer sein!' : passwordError}
                                    />
                                    <CustomButton
                                        buttontype={'blueYellow'}
                                        onClick={() => {
                                            login();
                                        }}
                                        style={{}}
                                    >LOGIN</CustomButton>
                                </div>}


                                {/*{(onRegistration) &&*/}

                                {/*    <div style={{padding: '15px 15px 15px 15px'}} className={'registrationMenu'}>*/}
                                {/*        /!*<p style={{fontFamily: 'VagRoundedBold', color: 'white', paddingBottom: 20}}>Registrierung</p>*!/*/}


                                {/*        <CssTextField3*/}
                                {/*            label="E-Mail*"*/}
                                {/*            InputLabelProps={{*/}
                                {/*                shrink: true*/}
                                {/*            }}*/}
                                {/*            // className={'text-input-label-blue'}*/}
                                {/*            style={{*/}
                                {/*                transition: 'all 0.2s ease',*/}
                                {/*                margin: '10px 5px 0 0',*/}
                                {/*                minHeight: 60,*/}
                                {/*                width: 'calc(100% - 5px)'*/}
                                {/*            }}*/}
                                {/*            autoComplete={'off'}*/}
                                {/*            onChange={e => {*/}
                                {/*                if (e.target.value.trim().length === 0) {*/}
                                {/*                    setMailError(true);*/}
                                {/*                } else {*/}
                                {/*                    setMailError(false);*/}
                                {/*                }*/}
                                {/*                setMail(e.target.value);*/}
                                {/*            }}*/}
                                {/*            className={'text-input-label-blue'}*/}
                                {/*            onKeyPress={handleKeypress}*/}
                                {/*            value={mail}*/}
                                {/*            error={!!mailError}*/}
                                {/*            helperText={mailError === true ? 'E-Mail darf nicht leer sein!' : mailError}*/}
                                {/*        />*/}

                                {/*        <CssTextField3*/}
                                {/*            label="Benutzername*"*/}
                                {/*            InputLabelProps={{*/}
                                {/*                shrink: true*/}
                                {/*            }}*/}
                                {/*            // className={'text-input-label-blue'}*/}
                                {/*            style={{*/}
                                {/*                transition: 'all 0.2s ease',*/}
                                {/*                margin: '10px 5px 10px 0',*/}
                                {/*                minHeight: 60,*/}
                                {/*                width: 'calc(100% - 5px)'*/}
                                {/*            }}*/}
                                {/*            autoComplete={'off'}*/}
                                {/*            onChange={e => {*/}
                                {/*                if (e.target.value.trim().length === 0) {*/}
                                {/*                    setUsernameError(true);*/}
                                {/*                } else {*/}
                                {/*                    setUsernameError(false);*/}
                                {/*                }*/}
                                {/*                setUsername(e.target.value);*/}
                                {/*            }}*/}
                                {/*            className={'text-input-label-blue'}*/}
                                {/*            onKeyPress={handleKeypress}*/}
                                {/*            value={username}*/}
                                {/*            error={!!usernameError}*/}
                                {/*            helperText={usernameError === true ? 'Benutzername darf nicht leer sein!' : usernameError}*/}
                                {/*        />*/}
                                {/*        <FormControl sx={{width: '100%'}} className={'testClassSelect'}>*/}
                                {/*            <InputLabel>Typ*</InputLabel>*/}
                                {/*            <Select2*/}
                                {/*                value={accountType}*/}
                                {/*                onChange={(e) => setAccountType(e.target.value)}*/}
                                {/*                label="Public"*/}
                                {/*                fullWidth*/}
                                {/*                style={{width: 'calc(100% - 5px)', marginBottom: 10}}*/}
                                {/*                MenuProps={MenuProps}*/}
                                {/*                input={<Select2 />}*/}
                                {/*            >*/}
                                {/*                <MenuItem value={false}>Arbeitgeber</MenuItem>*/}
                                {/*                <MenuItem value={true}>Arbeitnehmer</MenuItem>*/}
                                {/*            </Select2>*/}
                                {/*        </FormControl>*/}
                                {/*        <CssTextField3*/}
                                {/*            label="Passwort*"*/}
                                {/*            InputLabelProps={{*/}
                                {/*                shrink: true*/}
                                {/*            }}*/}
                                {/*            type="password"*/}
                                {/*            // className={'text-input-label-blue'}*/}
                                {/*            style={{*/}
                                {/*                transition: 'all 0.2s ease',*/}
                                {/*                margin: '20px 5px 0 0',*/}
                                {/*                minHeight: 60,*/}
                                {/*                width: 'calc(100% - 5px)'*/}
                                {/*            }}*/}
                                {/*            onChange={e => {*/}
                                {/*                if (e.target.value.trim().length === 0) {*/}
                                {/*                    setPasswordError(true);*/}
                                {/*                } else {*/}
                                {/*                    setPasswordError(false);*/}
                                {/*                }*/}
                                {/*                setPassword(e.target.value);*/}
                                {/*            }}*/}
                                {/*            className={'text-input-label-blue'}*/}
                                {/*            onKeyPress={handleKeypress}*/}
                                {/*            value={password}*/}
                                {/*            error={!!passwordError}*/}
                                {/*            helperText={passwordError === true ? 'Passwort darf nicht leer sein!' : passwordError}*/}
                                {/*        />*/}

                                {/*        <CssTextField3*/}
                                {/*            label="Passwort wiederholen*"*/}
                                {/*            InputLabelProps={{*/}
                                {/*                shrink: true*/}
                                {/*            }}*/}
                                {/*            type="password"*/}
                                {/*            // className={'text-input-label-blue'}*/}
                                {/*            style={{*/}
                                {/*                transition: 'all 0.2s ease',*/}
                                {/*                margin: '10px 5px 0 0',*/}
                                {/*                minHeight: 60,*/}
                                {/*                width: 'calc(100% - 5px)'*/}
                                {/*            }}*/}
                                {/*            onChange={e => {*/}
                                {/*                if (e.target.value.trim().length === 0) {*/}
                                {/*                    setPasswordVerifyError(true);*/}
                                {/*                } else {*/}
                                {/*                    setPasswordVerifyError(false);*/}
                                {/*                }*/}
                                {/*                setPasswordVerify(e.target.value);*/}
                                {/*            }}*/}
                                {/*            className={'text-input-label-blue'}*/}
                                {/*            onKeyPress={handleKeypress}*/}
                                {/*            value={passwordVerify}*/}
                                {/*            error={!!passwordVerifyError}*/}
                                {/*            helperText={passwordVerifyError === true ? 'Passwort darf nicht leer sein!' : passwordVerifyError}*/}
                                {/*        />*/}


                                {/*        <button className={'fadeInOut'} onMouseOver={() => setButtonHover('loginButton')}*/}
                                {/*                onMouseOut={() => setButtonHover('')} style={{*/}
                                {/*            fontFamily: 'VagRoundedBold',*/}
                                {/*            background: buttonHover == 'loginButton' ? 'white' : '#00374e',*/}
                                {/*            cursor: 'pointer',*/}
                                {/*            width: '100%',*/}
                                {/*            textAlign: 'center',*/}
                                {/*            padding: '6px 10px 6px 10px',*/}
                                {/*            borderRadius: ENV.REACT_APP_BORDER_RADIUS,*/}
                                {/*            color: buttonHover == 'loginButton' ? '#C7D300' : 'white',*/}
                                {/*            border: '2px solid ' + (buttonHover == 'loginButton' ? '#C7D300' : '#00374e'),*/}
                                {/*        }} onClick={() => {*/}
                                {/*            register();*/}
                                {/*            // loginRef.current.reg(mail, username, password, passwordVerify).then((e) => {*/}
                                {/*            //     console.log(e);*/}
                                {/*            //*/}
                                {/*            //     setMailError(e.mError);*/}
                                {/*            //     setUsernameError(e.uError);*/}
                                {/*            //     setPasswordError(e.pError);*/}
                                {/*            //     setPasswordVerifyError(e.pVError);*/}
                                {/*            // })*/}
                                {/*        }}>REGISTRIEREN*/}
                                {/*        </button>*/}
                                {/*        <div style={{fontSize: 14, float: 'left', marginTop: 10}} className={'color-blue'}>* Pflichtfelder</div>*/}
                                {/*        <div style={{textAlign: 'right', float: 'right', marginTop: 10}}>*/}
                                {/*            <Link onClick={() => {setOnRegistration(false)}} style={{cursor: 'pointer', fontSize: 14}}>Login</Link>*/}
                                {/*        </div>*/}
                                {/*    </div>}*/}


                            </div>}
                            {/*{user && <Box>*/}
                            {/*    <IconButton*/}
                            {/*        size="large"*/}
                            {/*        color="inherit"*/}
                            {/*        style={{marginLeft: 10, padding: 5}}*/}
                            {/*        onClick={() => {*/}
                            {/*            changeMenuState();*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        <MenuIcon className={isArbeitnehmer ? 'menuIconWhite' : 'menuIcon'}/>*/}
                            {/*    </IconButton>*/}
                            {/*</Box>}*/}
                        </Toolbar>
                    </Container>
                </AppBar>
                <Modal
                    open={onPasswordForget}
                    onClose={() => {
                        setMailPasswordForget('');
                        setMailPasswordForgetError(false);
                        setOnPasswordForget(false);
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={passwordStyle}>
                        <h3 style={{marginTop: 0, marginBottom: 30, fontFamily: 'VagRoundedBold'}}>Passwort
                            vergessen<QuestionMarkIcon
                                style={{
                                    color: '#92D050',
                                    marginBottom: -5,
                                    width: 40,
                                    height: 40,
                                }}
                            /></h3>

                        <p style={{fontSize: 12}}>Gib hier deine E-Mail-Adresse ein. Du erhälst von uns umgehend eine Mail,
                            mit der du dein Passwort zurücksetzen kannst.</p>

                        <CssTextField3
                            label="E-Mail-Adresse"
                            // InputLabelProps={{
                            //     shrink: true
                            // }}
                            // className={'text-input-label-blue'}
                            style={{
                                transition: 'all 0.2s ease',
                                // margin: '0 0 8px 0',
                                // minHeight: 60,
                                marginTop: (isShrinkPasswordForget && isShrinkPasswordForget.includes('E-Mail') || mailPasswordForget) ? 20 : 5,
                                width: '100%'
                            }}
                            onFocus={() => {
                                let t = [...isShrinkPasswordForget];
                                t.push('E-Mail');
                                setIsShrinkPasswordForget(t);
                            }}
                            onBlur={() => {
                                setIsShrinkPasswordForget([]);
                            }}
                            autoComplete={'off'}
                            onChange={e => {
                                if (e.target.value.trim().length === 0) {
                                    setMailPasswordForgetError(true);
                                } else {
                                    setMailPasswordForgetError(false);
                                }
                                setMailPasswordForget(e.target.value);
                            }}
                            className={'label-black'}
                            // onKeyPress={handleKeypress}
                            value={mailPasswordForget}
                            error={!!mailPasswordForgetError}
                            helperText={mailPasswordForgetError}
                        />
                        <CustomButton
                            buttontype={'yellowBlue'}
                            onClick={() => {
                                // login();
                                requestNewPassword();
                                console.log('Passwort vergessen senden')
                            }}
                            style={{
                                marginTop: 20,
                                width: '100%'
                            }}
                        >Senden</CustomButton>
                        <p style={{fontSize: 10}}>E-Mail-Adresse vergessen? Dann schreib uns gerne eine Nachricht an <a
                            href={'mailto:info@swiitcher.de'}>info@swiitcher.de</a></p>
                    </Box>
                </Modal>
                <Modal
                    open={onPasswordReset}
                    onClose={() => {
                        setMailPassword1Reset('');
                        setMailPassword1ResetError(false);
                        setMailPassword2Reset('');
                        setMailPassword2ResetError(false);
                        setOnPasswordReset(false);
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={passwordStyle}>
                        <h3 style={{marginTop: 0, marginBottom: 30, fontFamily: 'VagRoundedBold'}}>Neues Passwort
                            vergeben<PriorityHigh
                                style={{
                                    color: '#92D050',
                                    marginBottom: -5,
                                    width: 40,
                                    height: 40,
                                }}
                            /></h3>

                        <p style={{fontSize: 12}}>Dein Passwort muss min. 8 Zeichen lang sein, einen Groß- und einen
                            Kleinbuchstaben sowie ein Sonderzeichen bzw. eine Zahl enthalten. Es muss sich von deinem alten
                            Passwort unterscheiden.</p>

                        <CssTextField3
                            label="Passwort"
                            // InputLabelProps={{
                            //     shrink: true
                            // }}
                            // className={'text-input-label-blue'}
                            style={{
                                transition: 'all 0.2s ease',
                                // margin: '0 0 8px 0',
                                // minHeight: 60,
                                marginTop: (isShrinkPassword1Reset && isShrinkPassword1Reset.includes('E-Mail') || mailPassword1Reset) ? 20 : 5,
                                width: '100%'
                            }}
                            autoFocus
                            type={showPassword1 ? "text" : "password"}
                            InputProps={{ // <-- This is where the toggle button is added.
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword1}
                                            onMouseDown={handleMouseDownPassword1}
                                        >
                                            {showPassword1 ? <Visibility style={{color: '#C7D300'}}/> :
                                                <VisibilityOff style={{color: '#C7D300'}}/>}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                autocomplete: 'new-password',
                                form: {
                                    autocomplete: 'off',
                                },
                            }}
                            onFocus={() => {
                                let t = [...isShrinkPassword1Reset];
                                t.push('E-Mail');
                                setIsShrinkPassword1Reset(t);
                            }}
                            onBlur={() => {
                                setIsShrinkPassword1Reset([]);
                            }}
                            autoComplete="new-password"
                            onChange={e => {
                                if (e.target.value.trim().length === 0) {
                                    setMailPassword1ResetError(true);
                                } else {
                                    setMailPassword1ResetError(false);
                                }
                                setMailPassword1Reset(e.target.value);
                            }}
                            className={'label-black'}
                            // onKeyPress={handleKeypress}
                            value={mailPassword1Reset}
                            error={!!mailPassword1ResetError}
                            helperText={mailPassword1ResetError}
                        />
                        <CssTextField3
                            label="Passwort wiederholen"
                            // InputLabelProps={{
                            //     shrink: true
                            // }}
                            // className={'text-input-label-blue'}
                            style={{
                                transition: 'all 0.2s ease',
                                // margin: '0 0 8px 0',
                                // minHeight: 60,
                                marginTop: (isShrinkPassword2Reset && isShrinkPassword2Reset.includes('E-Mail') || mailPassword2Reset) ? 20 : 5,
                                width: '100%'
                            }}
                            type={showPassword2 ? "text" : "password"}
                            InputProps={{ // <-- This is where the toggle button is added.
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword2}
                                            onMouseDown={handleMouseDownPassword2}
                                        >
                                            {showPassword2 ? <Visibility style={{color: '#C7D300'}}/> :
                                                <VisibilityOff style={{color: '#C7D300'}}/>}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                autocomplete: 'new-password',
                                form: {
                                    autocomplete: 'off',
                                },
                            }}
                            onFocus={() => {
                                let t = [...isShrinkPassword2Reset];
                                t.push('E-Mail');
                                setIsShrinkPassword2Reset(t);
                            }}
                            onBlur={() => {
                                setIsShrinkPassword2Reset([]);
                            }}
                            autoComplete="new-password"
                            onChange={e => {
                                if (e.target.value.trim().length === 0) {
                                    setMailPassword2ResetError(true);
                                } else {
                                    setMailPassword2ResetError(false);
                                }
                                setMailPassword2Reset(e.target.value);
                            }}
                            className={'label-black'}
                            // onKeyPress={handleKeypress}
                            value={mailPassword2Reset}
                            error={!!mailPassword2ResetError}
                            helperText={mailPassword2ResetError}
                        />
                        <CustomButton
                            buttontype={'yellowBlue'}
                            onClick={() => {
                                // login();
                                updatePassword();
                                console.log('Neues Passwort senden')
                            }}
                            style={{
                                marginTop: 20,
                                width: '100%'
                            }}
                        >Speichern</CustomButton>
                    </Box>
                </Modal>
            </div>
        );
    })
;
export default MenuAppBar;
