import React, {useEffect, useMemo, useRef, useState} from 'react';
import './App.css';
import {createTheme, Grid} from "@mui/material";
import {ThemeProvider} from "@emotion/react";
import MenuAppBar from "./MenuAppBar";
import {BrowserRouter as Router, Route, Switch,} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import moment from 'moment';
import 'moment/locale/de';
import ScrollToTop from "./components/ScrollToTop";
import useWindowDimensions from "./components/useWindowDimensions";
import {ReactComponent as LogoSvg} from './images/svg/swiitcher_logo.svg';
import {ReactComponent as LogoSvgWhite} from './images/svg/swiitcher_logo_weiss.svg';
import {isMobile} from 'react-device-detect';
// import {TransitionGroup, CSSTransition} from 'react-transition-group'
import {ReactSession} from 'react-client-session';
import ScrollToTopOnPageLoad from "./components/ScrollToTopOnPageLoad";
// import history from "./components/history.js";
import debounce from 'lodash.debounce';
import useFetch from "./components/UseFetch";
import Home from "./components/Home";
import StellenanzeigenVerwalten from "./components/StellenanzeigenVerwalten";
import RechnungenVerwalten from "./components/RechnungenVerwalten";


const theme = createTheme({
    palette: {
        primary: {
            main: '#00374e',
        },
        secondary: {
            main: '#C7D300',
        },
    },
});

moment.updateLocale('de', {
    relativeTime: {
        future: "in %s",
        past: "vor %s",
        s: function (number, withoutSuffix, key, isFuture) {
            return number + ' Sekunden';
        },
        m: "einer Minute",
        mm: function (number, withoutSuffix, key, isFuture) {
            return number + ' Minuten';
        },
        h: "einer Stunde",
        hh: "%d Stunden",
        d: "einem Tag",
        dd: "%d Tagen",
        M: "einem Monat",
        MM: "%d Monaten",
        y: "einem Jahr",
        yy: "%d jahren"
    }
});


function App() {


    // const history = useHistory();
    const {height, width} = useWindowDimensions();
    const menuAppBarRef = useRef();
    const api = useFetch();
    const [facebookButtonHover, setFacebookButtonHover] = useState(false);
    const [instagramButtonHover, setInstagramButtonHover] = useState(false);
    const [menuState, setMenuState] = useState(false);
    const [location, setLocation] = useState('/');
    const [avatarComp, setAvatarComp] = useState(null);
    const [login, setLogin] = useState(null);

    const [test, setTest] = useState(false);
    const [stickyButtonClick, setStickyButtonClick] = useState(false);
    const [stickyButtonClickHover, setStickyButtonClickHover] = useState(false);
    const [logoutButtonHover, setLogoutButtonHover] = useState(false);
    const [activeMenuItem, setActiveMenuitem] = useState('Übersicht');
    const [hoverMenuItem, setHoverMenuItem] = useState(null);
    const [menuItems, setMenuItems] = useState([]);
    const [overAllCount, setOverallCount] = useState(0);
    const [history, setHistory] = useState(null);

    const [currentRange, setCurrentRange] = useState(30);
    const [results, setResults] = useState(0);
    const [loading, setLoading] = useState(false);

    const [jobs, setJobs] = useState('');
    const [plz, setPlz] = useState('');
    const [isOpen, setIsOpen] = useState('');
    const [confirmedMatches, setConfirmedMatches] = useState(0);
    const [unreadMatches, setUnreadMatches] = useState(0);
    const [unreadChats, setUnreadChats] = useState(0);

    const [menuItemsArbeitgeber, setMenuItemsArbeitgeber] = useState([
        {text: 'Stellenanzeigen', url: 'stellenanzeigen', count: 0},
        {text: 'Rechnungen', url: 'rechnungen', count: 0},
    ]);


    useEffect(() => {
        ReactSession.setStoreType("localStorage");

        if (ReactSession.get('user')) {
            console.log(ReactSession.get('user'));
            setLogin(ReactSession.get('user'));
        }

        if (ReactSession.get('menuState')) {
            setMenuState(ReactSession.get('menuState'));
        }
    }, [])
    useEffect(() => {
        if (menuItems != menuItemsArbeitgeber) {
            setMenuItems(menuItemsArbeitgeber);
        }
    }, [login, menuItemsArbeitgeber])

    const locationChange = (location, token = null) => {
        location.token = token;
        setLocation(location);
        if (menuItems) {
            //setActiveMenuitem(menuItems.filter(i => (i.url && location.pathname.includes(i.url)))[0]?.text)
        }
    }

    const changeMenuState = () => {
        if (!menuState) {
            console.log('menu open')
        } else {
            console.log('menu close')
        }
        setMenuState(!menuState);
    }

    const notify = (message, error) => {
        console.log('NOTIFY')

        if (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 80}
            });
        } else {
            toast.success(message, {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 80}
            });
        }

    }

    const update = (login) => {
        setLogin(login);
        setMenuState(!menuState);
    }

    const getHistory = (history) => {
        setHistory(history);
    }

    const handleMouseIn = (type) => {
        switch (type) {
            case 'facebookButton':
                setFacebookButtonHover(true);
                break;
            case 'instagramButton':
                setInstagramButtonHover(true);
                break;
            case 'logoutButton':
                setLogoutButtonHover(true);
                break;
        }
    };

    const handleMouseOut = (type) => {
        switch (type) {
            case 'facebookButton':
                setFacebookButtonHover(false);
                break;
            case 'instagramButton':
                setInstagramButtonHover(false);
                break;
            case 'logoutButton':
                setLogoutButtonHover(false);
                break;
        }
    };

    return (
        <React.Fragment>
            {!isMobile ? <Router basename={ENV.REACT_APP_BASE_URL}>
                <ToastContainer style={{margin: '0 auto', width: 'auto'}}/>
                <ThemeProvider theme={theme}>
                    <ScrollToTopOnPageLoad/>
                    <div className="App"
                         style={{
                             background: location.pathname == '/arbeitgeber' ? '#00374e' : '#FFFFFF',
                             lineHeight: 1.4
                         }}>

                        <header className={'fadeInOut'} style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            zIndex: 999,
                            boxShadow: '0 2px 24px 0 rgb(0 0 0 / 15%)',
                            paddingTop: 0
                        }}>
                            <MenuAppBar logiin={login} getHistory={getHistory} overAllCount={overAllCount}
                                        menuState={menuState}
                                        userT={login} ref={menuAppBarRef} update={update}
                                        notify={notify}
                                        changeMenuState={changeMenuState} location={location}
                                        locationChange={locationChange} LogoSvgWhite={LogoSvgWhite}/>
                        </header>


                        <React.Fragment>
                            <div className={'fadeInOut'} style={{
                                zIndex: 1,
                                visibility: menuState ? 'visible' : 'hidden',
                                position: 'fixed',
                                top: 64,
                                right: menuState ? 0 : -210,
                                width: 210,
                                height: '100%',
                                backgroundColor: '#00374e',
                                padding: '0 0 0 10px',
                                boxSizing: 'border-box',
                                boxShadow: 'rgb(0 0 0 / 25%) 5px 5px 5px 7px',
                                paddingTop: 20
                            }}>
                                {avatarComp}
                                {/*<h3 style={{color: location.pathname == '/arbeitgeber' ? '#FFFFFF' : '#00374e'}}>*/}
                                {/*    Lorem Ipsum*/}
                                {/*</h3>*/}

                                {menuItems && Object.keys(menuItems).map((key, index) => {
                                    let item = menuItems[key];
                                    return (<div style={{position: 'relative'}}>
                                        <p
                                            key={index}
                                            style={{
                                                textAlign: 'left',
                                                padding: (item.count > 0 ? '5px 0 4px 15px' : '7px 0 4px 15px'),
                                                position: 'relative',
                                                zIndex: 1
                                            }}
                                            className={'menuItemMain ' + ((activeMenuItem == item.text) ? 'active ' : '')}
                                            onMouseOver={() => setHoverMenuItem(item.text)}
                                            onMouseOut={() => setHoverMenuItem(null)}
                                            onClick={() => {
                                                setActiveMenuitem(item.text)
                                                if (item.url) history.push('/' + item.url);
                                                else history.push('/');
                                            }}
                                        >
                                            <span style={{fontFamily: (item.count > 0 ? 'VagRoundedBold' : '')}}
                                                  key={index}
                                                  className={'menuItem ' + 'arbeitgeber' + (hoverMenuItem == item.text ? ' hover ' : ' ')}
                                            >
                                            {item.unterpunkt ? ("- " + item.text) : item.text}
                                                {item.count > 0 && <span className={'count'}>{item.count}</span>}

                                        </span>
                                        </p></div>)
                                })}

                                <div style={{textAlign: 'left', marginTop: 10, marginLeft: 13}}>
                                    <button className={'fadeInOut'} onMouseOver={() => handleMouseIn('logoutButton')}
                                            onMouseOut={() => handleMouseOut('logoutButton')} style={{
                                        fontFamily: 'VagRoundedBold',
                                        //background: logoutButtonHover ? '#00374e' : '#C7D300',
                                        background: logoutButtonHover ? '#00374e' : '#C7D300',
                                        cursor: 'pointer',
                                        textAlign: 'center',
                                        padding: '6px 10px 6px 10px',
                                        borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                        color: logoutButtonHover ? '#C7D300' : '#00374e',
                                        border: '2px solid ' + (logoutButtonHover ? '#C7D300' : '#00374e'),
                                    }} onClick={() => {
                                        menuAppBarRef.current.logOut()
                                    }}>ausloggen
                                    </button>
                                </div>
                            </div>
                        </React.Fragment>
                        <div className={'fadeInOut'}
                             style={{minHeight: '100vh', marginRight: menuState ? 210 : 0, position: 'relative', overflow: 'hidden'}}>
                            {/*     /!* A <Switch> looks through its children <Route>s and*/}
                            {/*renders the first one that matches the current URL. *!/*/}

                            <ScrollToTop/>
                            {/*<TransitionGroup>*/}
                            {/*<CSSTransition*/}
                            {/*    timeout={300}*/}
                            {/*    classNames='fade'*/}
                            {/*    key={location.key}*/}
                            {/*>*/}
                            <React.Fragment>
                                <Switch>

                                    <Route path="/stellenanzeigen">
                                        <StellenanzeigenVerwalten theme={theme} locationChange={locationChange}/>
                                    </Route>

                                    <Route path="/rechnungen">
                                        <RechnungenVerwalten theme={theme} locationChange={locationChange}/>
                                    </Route>

                                    {/*/!* Main entry page *!/*/}
                                    <Route path="/">
                                        <Home theme={theme} locationChange={locationChange}/>
                                    </Route>
                                </Switch>
                                <footer style={{
                                    background: '#FFFFFF',
                                    textAlign: 'left',
                                    //marginRight: menuState ? 200 : 0,
                                }}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <p style={{marginTop: 20, textAlign: 'center'}}>© TT-Verlag</p>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </footer>
                            </React.Fragment>
                            {/*</CSSTransition>*/}
                            {/*</TransitionGroup>*/}
                        </div>
                    </div>

                </ThemeProvider>
            </Router> : <React.Fragment>
                <div style={{marginTop: 100, textAlign: 'center'}}>
                    <LogoSvg
                        style={{
                            width: 350,
                            marginBottom: 50
                        }}
                        id={'logoFooter'}
                    />
                    <h1>Work in progress.</h1>
                    <p style={{margin: 'auto', width: 300}}>Nur als Desktop-Version verfügbar, bis die Optimierungen für
                        mobile Endgeräte abgeschlossen sind.</p>
                </div>
            </React.Fragment>}
        </React.Fragment>
    );
}

export default App
